<ng-container *ngIf="!isIE">
  <app-navbar></app-navbar>

  <div class="page">
    <router-outlet (activate)="onActivate()"></router-outlet>

    <div class="whatsapp-container">
      <a aria-label="Chat met WhatsApp" href="https://wa.me/31630999601">
        <img alt="Chat met WhatsApp" src="/assets/content/whasapp-white.png" />
        <div class="description" style="visibility: hidden">
          Chat met ons
        </div>
      </a>
    </div>
  </div>

  <app-footer></app-footer>
</ng-container>

<!-- Is IE browser -->
<ng-container *ngIf="isIE">
  <div class="ie-supper"></div>
  <div class="bg-pannel">
    <div class="title">Om veilig te kunnen winkelen bij Slagerij Goënga hebben wij voor onze nieuwe webshop besloten
      Microsoft Internet Explorer niet langer te ondersteunen.
    </div>
    <div class="sub-title">Om bij ons te kunnen winkelen kan je onder andere gebruik maken van de volgende browsers
    </div>
    <div class="browsers">
      <span>Microsoft Edge</span>
      <span>FireFox</span>
      <span>Chrome</span>
      <span>Safari</span>
      <span>Opera</span>
    </div>
  </div>
</ng-container>
import { EventEmitter, Injectable, Output, Directive } from "@angular/core";

@Directive()
@Injectable()
export class ShoppingCartService {
  isOpen = false;

  @Output() change: EventEmitter<boolean> = new EventEmitter();

  showFast() {
    this.isOpen = true;
    this.change.emit(this.isOpen);
    setTimeout(() => {
      this.isOpen = false;
      this.change.emit(this.isOpen);
    }, 3000);
  }
}

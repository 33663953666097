<div class="input-group">
  <div class="input-group-prepend">
    <div class="input-group-text">
      <i [class]="icon"></i>
    </div>
  </div>
  <input
    [ngModel]="displayLabel"
    [customTypeahead]="values"
    [typeaheadOptionsLimit]="5"
    (typeaheadOnSelect)="changeValue($event)"
    [typeaheadSearchFields]="searchFields"
    [typeaheadItemTemplate]="customItemTemplate"
    [typeaheadOptionField]="optionField"
    [placeholder]="placeholder"
    class="form-control"
  />
  <span id="searchclear" class="fa fa-times" (click)="clear()"></span>
</div>

<footer class="footer">
  <div class="rectangle"></div>
  <div class="logo-shape">
    <img class="logo" src="./assets/images/logo.png" />
  </div>

  <div class="quick-links">
    <div class="footer-container">
      <div class="header">Adres</div>
      <div class="address">
        <span class="line">De naal 1</span>
        <span class="line">1797 AX Den Hoorn, Texel</span>
        <span class="line">0222-319252</span>
        <span class="line">winkel@goenga.com</span>
      </div>
    </div>
    <div class="footer-container">
      <div class="header">Snel naar</div>
      <div class="links-container">
        <a class="link" routerLink="/bestellen">Online bestellen</a>
        <a class="link" routerLink="/account">Mijn account</a>
        <a class="link" routerLink="/heerlijke-recpeten">Bereidingstips</a>
      </div>
    </div>
    <div class="footer-container">
      <div class="links-container">
        <a class="link" routerLink="over-slagerij-goenga">Over ons</a>
        <a class="link" routerLink="herkomst-van-ons-vlees">Herkomst producten</a>
        <a class="link" routerLink="informatie/verzend-informatie">Verzending</a>
      </div>
    </div>
    <div class="footer-container">
      <div class="links-container">
        <a class="link" routerLink="informatie/hoe-werkt-het-bestellen">Bestelinformatie</a>
        <a class="link" routerLink="contact">Contact</a>
        <a class="link" routerLink="authenticatie/registreren">Zakelijk bestellen</a>
      </div>
    </div>
  </div>

  <div class="texels-product">
    <img class="logo" src="/assets/content/texels-product-logo.png" />
  </div>

  <div class="about">
    Slagerij Goënga - 2023 &copy; | Website door EnJu i.s.m. Digitallo
  </div>
</footer>
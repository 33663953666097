import { Component, OnInit, Input } from "@angular/core";

import { Router } from "@angular/router";
import { OrderRegel } from "src/app/api-service";
import { SessionStorageService } from "src/app/services/session-storage.service";
import { ShoppingCartService } from "src/app/services/shopping-cart.service";

@Component({
  selector: "shopping-cart-modal",
  templateUrl: "./shopping-cart-modal.component.html",
  styleUrls: ["./shopping-cart-modal.component.scss"],
})
export class ShoppingCartModalComponent implements OnInit {
  public items: OrderRegel[];
  public timer: any;
  public show: boolean;

  constructor(
    private sessionStorageService: SessionStorageService,
    private router: Router,
    private shoppingCartService: ShoppingCartService
  ) { }

  ngOnInit() {
    this.items = this.sessionStorageService.shoppingCartItems;
    this.shoppingCartService.change.subscribe(show => this.show = show);
  }

  navigate() {
    this.router.navigate(["/bestellen/winkelwagen"]);
  }
}

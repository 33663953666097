export class CurrentUser {
    id?: number;
    tenantId?: string;
    username?: string | undefined;
    email?: string | undefined;
    imageUrl?: string | undefined;
    fullName?: string | undefined;
    role?: string | undefined;
    application: string
    customerId: number;
    customer?: Customer
}

export class Customer {
    public id: number;
    public firstName: string;
    public middleName: string;
    public lastName: string;
    public isParticulier: boolean = true;
    public companyName: string;
}
<nav class="goenga-navbar">
  <div class="top-bar">
    <div class="top-bar-container">
      <div class="toggler">
        <button class="navbar-toggler float-right"
                style="border: none; outline: none"
                (click)="isShown = !isShown">
          <button class="hamburger hamburger--collapse"
                  style="border: none; outline: none"
                  [ngClass]="{ 'is-active': isShown }"
                  type="button">
            <span class="hamburger-box">
              <span class="hamburger-inner"></span>
            </span>
          </button>
        </button>
      </div>
      <div class="info">
        <div class="phone">
          <i class="fa fa-phone fa-rotate-90 mr-2"></i>
          0222-319252
        </div>
        <div class="whatsapp">
          <i class="fab fa-whatsapp mr-1"></i>
          <a href="https://wa.me/31630999601">
            0630999601
          </a>
        </div>
        <div class="email">
          <i class="fa fa-at mr-1"></i>
          <a href="mailto:winkel@goenga.com">
            winkel@goenga.com
          </a>
        </div>
      </div>
      <div class="action-bar">
        <div class="nav-btn-group">
          <goenga-searcher></goenga-searcher>
          <button class="nav-button search">
            <i class="goenga-icon icon-zoek"></i>
          </button>
          <button class="nav-button login" goengaDropDown>
            <i class="goenga-icon icon-user"></i>
            <span class="user-login-badge" *ngIf="user">
              <i class="fa fa-check"></i>
            </span>
            <!-- if user not is authenticated -->
            <div *ngIf="!user" class="goenga-dropdown user-dropdown-menu">
              <div class="title">Mijn slagerij Goënga</div>
              <div class="item">
                <a [routerLink]="['/authenticatie/inloggen']"
                   gaEvent="nav_login_click"
                   gaCategory="navigation">
                  <i class="goenga-icon icon-user mr-2"></i>
                  Inloggen met mijn account
                </a>
              </div>
            </div>
            <!-- if user  is authenticated -->
            <div *ngIf="user" class="goenga-dropdown user-dropdown-menu">
              <div class="title">Hallo {{ user.fullName }}</div>
              <div class="item" *ngIf="!isParticulier">
                <a [routerLink]="['/horeca/bestellijst']"
                   gaEvent="nav_order_list_click"
                   gaCategory="navigation">
                  <i class="goenga-icon icon-box mr-2"></i>
                  Mijn bestellijst
                </a>
              </div>
              <div class="item">
                <a [routerLink]="['/account']"
                   gaEvent="nav_account_click"
                   gaCategory="navigation">
                  <i class="goenga-icon icon-user mr-2"></i>
                  Mijn account
                </a>
              </div>
              <div class="item">
                <a [routerLink]="['/account/order-historie']">
                  <i class="goenga-icon icon-box mr-2"></i>
                  Bestelhistorie
                </a>
              </div>
              <div class="item" (click)="logout()">
                <i class="goenga-icon icon-logout mr-2"></i>
                Uitloggen
              </div>
            </div>
          </button>
          <button class="nav-button shoppingcart" goengaDropDown [routerLink]="['/bestellen/winkelwagen']"
                  *ngIf="isParticulier">
            <i class="goenga-icon icon-mantje"></i>
            <span class="shopping-cart-badge"
                  *ngIf="sessionStorageService.shoppingCartItems.length > 0">
              {{ sessionStorageService.shoppingCartItems.length }}
            </span>
            <shopping-cart-modal></shopping-cart-modal>
          </button>
          <button class="nav-button shoppingcart" [routerLink]="['/horeca/bestellijst']" *ngIf="!isParticulier">
            <i class="goenga-icon icon-mantje"></i>
          </button>
        </div>
      </div>
    </div>
  </div>
  <div class="menu-bar">
    <div class="menu-bar-container">
      <div class="logo-container">
        <img class="logo" src="./assets/images/logo.png" />
      </div>
      <div class="menu" [ngClass]="{ show: isShown }" #menu>
        <ul class="menu-group mr-auto">
          <li class="menu-item active">
            <a class="menu-link" (click)="isShown = false" [routerLink]="['/']">Home
            </a>
          </li>
          <li class="menu-item">
            <a class="menu-link"
               (click)="isShown = false"
               gaEvent="nav_order_click"
               gaCategory="navigation"
               [routerLink]="['/bestellen']">
              Bestellen
            </a>
          </li>
          <li class="menu-item">
            <a class="menu-link"
               (click)="isShown = false"
               gaEvent="nav_about_click"
               gaCategory="navigation"
               [routerLink]="['/over-slagerij-goenga']">
              Over ons
            </a>
          </li>
          <li class="menu-item">
            <a class="menu-link"
               gaEvent="nav_texel_click"
               gaCategory="navigation"
               (click)="isShown = false"
               routerLink="over-texel">
              Texel
            </a>
          </li>
          <li class="menu-item">
            <a class="menu-link"
               gaEvent="nav_contact_click"
               gaCategory="navigation"
               (click)="isShown = false"
               routerLink="contact">
              Contact
            </a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</nav>

<nav class="goenga-footer-menu">
  <div class="nav-btn-group">
    <goenga-searcher dropup="true"></goenga-searcher>
    <button class="nav-button search">
      <i class="goenga-icon icon-zoek"></i>
    </button>
    <button class="nav-button login" (click)="navigateForLogin()">
      <i class="goenga-icon icon-user"></i>
      <span class="user-login-badge" *ngIf="user">
        <i class="fa fa-check"></i>
      </span>
    </button>
    <button class="nav-button shoppingcart"
            *ngIf="isParticulier"
            [routerLink]="['/bestellen/winkelwagen']">
      <i class="goenga-icon icon-mantje"></i>
      <span class="shopping-cart-badge"
            *ngIf="sessionStorageService.shoppingCartItems.length > 0">
        {{ sessionStorageService.shoppingCartItems.length }}
      </span>
      <shopping-cart-modal></shopping-cart-modal>
    </button>
    <button class="nav-button shoppingcart" [routerLink]="['/horeca/bestellijst']" *ngIf="!isParticulier">
      <i class="goenga-icon icon-mantje"></i>
    </button>
  </div>
</nav>
import { Component, Input, OnInit } from "@angular/core";

@Component({
  selector: "goenga-pig-specify-image",
  templateUrl: "./pig-specify-image.component.html",
  styleUrls: ["./pig-specify-image.component.scss"],
})
export class PigSpecifyImageComponent implements OnInit {
  @Input() part: string;

  constructor() {}

  ngOnInit(): void {}
}

<input
       [(ngModel)]="selected"
       [typeahead]="searchItems"
       [dropup]="dropup"
       typeaheadOptionField="name"
       typeaheadGroupField="type"
       (typeaheadOnSelect)="onSelect($event)"
       [optionsListTemplate]="customListTemplate"
       placeholder="Waar ben je naar opzoek?" />

<ng-template
             #customListTemplate
             let-matches="matches"
             let-query="query"
             let-typeaheadTemplateMethods>
  <ul class="custom-list-group" [class.dropdown-menu]="isBs3">
    <li
        class="custom-list-group-item"
        *ngFor="let match of matches.slice(0, 5)"
        [class.active]="typeaheadTemplateMethods.isActive(match)"
        (click)="typeaheadTemplateMethods.selectMatch(match, $event)"
        (mouseenter)="typeaheadTemplateMethods.selectActive(match)">
      <div class="title" *ngIf="match.header">{{ match.value }}</div>
      <div class="row">
        <div class="col-7 my-auto">
          <h5>{{ match.item.name }}</h5>
        </div>
        <div class="col-5 my-auto">
          <goenga-azure-image [imageName]="match.item.imageUrl" *ngIf="match.item.imageUrl != null">
          </goenga-azure-image>
        </div>
      </div>
      <div class="row">
        <div class="col-12" [innerHTML]="match.item.description"></div>
      </div>
    </li>
  </ul>
</ng-template>
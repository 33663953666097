import { NgModule } from "@angular/core";
import { Routes, RouterModule, PreloadAllModules } from "@angular/router";
import { AboutTexelComponent } from "./about-texel/about-texel.component";
import { AboutComponent } from "./about/about.component";
import { AuthGuard } from "./authentication/auth.guart";
import { ContactComponent } from "./contact/contact.component";
import { HomeComponent } from "./home/home.component";
import { RecipesComponent } from "./recipes/recipes.component";

const routes: Routes = [
  {
    path: "",
    component: HomeComponent,
    data: {
      title: "Start",
    },
  },
  {
    path: "",
    children: [
      {
        path: "bestellen",
        loadChildren: () =>
          import("./order/order.module").then((m) => m.OrderModule),
      },
      {
        path: "authenticatie",
        loadChildren: () =>
          import("./authentication/authentication.module").then(
            (m) => m.AuthenticationModule
          ),
      },
      {
        path: "horeca",
        canActivate: [AuthGuard],
        loadChildren: () =>
          import("./catering/catering.module").then((m) => m.CateringModule),
      },
      {
        path: "informatie",
        loadChildren: () =>
          import("./information/information.module").then(
            (m) => m.InformationModule
          ),
      },
      {
        path: "account",
        canActivate: [AuthGuard],
        loadChildren: () =>
          import("./account/account.module").then((m) => m.AccountModule),
      },
      {
        path: "betalen",
        loadChildren: () =>
          import("./payment/payment.module").then((m) => m.PaymentModule),
      },
      {
        path: "herkomst-van-ons-vlees",
        loadChildren: () =>
          import("./origin/origin.module").then((m) => m.OriginModule),
      }
    ],
  },
  {
    path: "over-slagerij-goenga",
    component: AboutComponent,
    data: {
      title: "Over ons",
    },
  },
  {
    path: "heerlijke-recpeten",
    component: RecipesComponent,
    data: {
      title: "Recepten",
    },
  },
  {
    path: "over-texel",
    component: AboutTexelComponent,
    data: {
      title: "Over Texel",
    },
  },
  {
    path: "contact",
    component: ContactComponent,
    data: {
      title: "Contact",
    },
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      relativeLinkResolution: "legacy",
      preloadingStrategy: PreloadAllModules,
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {
  public toggleNavbar: boolean;
}

<div *ngIf="buttonLoader">
  <div class="loader" style="width: 20px">
    <svg class="circular" viewBox="25 25 50 50">
      <circle
        class="buttonpath"
        cx="50"
        cy="50"
        r="20"
        fill="none"
        stroke-width="8"
        stroke-miterlimit="10"
      />
    </svg>
  </div>
</div>

<div *ngIf="!buttonLoader">
  <div class="loader" style="width: 50px">
    <svg class="circular" viewBox="25 25 50 50">
      <circle
        class="path"
        cx="50"
        cy="50"
        r="20"
        fill="none"
        stroke-width="4"
        stroke-miterlimit="10"
      />
    </svg>
  </div>
</div>

<div class="title">
  <h1>{{ title }}</h1>
</div>

<div class="page">
  <google-map
    height="100%"
    width="100%"
    [zoom]="zoom"
    [center]="center"
    [options]="options"
    class="picture"
  >
    <map-marker
      [position]="center"
      [label]="markerLabel"
      [title]="markerTitle"
      [options]="markerOptions"
    >
    </map-marker>
    <map-info-window>test</map-info-window>
  </google-map>
  <div class="content {{ size }}">
    <ng-content></ng-content>
  </div>
</div>

import { BrowserModule } from "@angular/platform-browser";
import { APP_INITIALIZER, NgModule } from "@angular/core";

import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { LayoutModule } from "./layout/layout.module";
import { AboutComponent } from "./about/about.component";
import { HomeComponent } from "./home/home.component";
import { RouterModule } from "@angular/router";
import { SharedModule } from "./shared/shared.module";
import { RecipesComponent } from "./recipes/recipes.component";
import { AboutTexelComponent } from "./about-texel/about-texel.component";
import { ContactComponent } from "./contact/contact.component";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { ApiCalculation, ApiProduct, API_BASE_URL } from "./api-service";
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { SessionStorageService } from "./services/session-storage.service";
import { ShoppingCartService } from "./services/shopping-cart.service";
import { AuthenticationService } from "./services/authentication.service";
import { TokenInterceptor } from "./authentication/token.interceptor";
import { environment } from "src/environments/environment";
import { TypeaheadModule } from "ngx-bootstrap/typeahead";
import { AccordionModule } from "ngx-bootstrap/accordion";
import { PaginationModule } from "ngx-bootstrap/pagination";
import { PwaService } from './services/pwa.service';
import { LazyLoadImageModule } from "ng-lazyload-image";
import { ToastrModule } from "ngx-toastr";
import { ServiceWorkerModule } from '@angular/service-worker';
import { OrderCostService } from "./services/order-cost.service";
import { NgxGoogleAnalyticsModule, NgxGoogleAnalyticsRouterModule } from "ngx-google-analytics";
import { NgcCookieConsentConfig, NgcCookieConsentModule } from 'ngx-cookieconsent';
import { cookieConfigFactory } from "./services/cookie.service";

const cookieConfig: NgcCookieConsentConfig = {
  cookie: {
    domain: 'not-set'
  },
  position: "bottom",
  theme: "classic",
  palette: {
    popup: {
      background: "#96b9a2",
      text: "#ffffff",
      link: "#ffffff"
    },
    button: {
      background: "#a39868",
      text: "#ffffff",
      border: "transparent"
    }
  },
  type: "info",
  content: {
    message: "Onze website maakt gebruik van cookies. We gebruiken deze cookies om onze website te verbeteren.",
    dismiss: "Ik begrijp het",
    deny: "Refuse cookies",
    link: "Meer informatie",
    href: "https://slagerijgoenga.nl/informatie/privacy-policy",
    policy: "Cookie Policy"
  }
};

@NgModule({
  declarations: [
    AppComponent,
    AboutComponent,
    HomeComponent,
    RecipesComponent,
    AboutTexelComponent,
    ContactComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    RouterModule,
    LayoutModule,
    SharedModule,
    HttpClientModule,
    TypeaheadModule.forRoot(),
    PaginationModule.forRoot(),
    AccordionModule.forRoot(),
    ToastrModule.forRoot(),
    LazyLoadImageModule,
    //NgxGoogleAnalyticsModule.forRoot(environment.ga),
    //NgxGoogleAnalyticsRouterModule.forRoot({ include: ['/full-uri-match'] }),
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
    }),
    NgcCookieConsentModule.forRoot(cookieConfig),
  ],
  providers: [
    HttpClientModule,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true,
    },
    ApiProduct,
    ApiCalculation,
    SessionStorageService,
    ShoppingCartService,
    AuthenticationService,
    OrderCostService,
    Document,
    {
      provide: APP_INITIALIZER,
      useFactory: cookieConfigFactory,
      deps: [HttpClient, Document, NgcCookieConsentConfig],
      multi: true
    },
    PwaService,
    {
      provide: API_BASE_URL,
      useValue: environment.apiUrl,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }

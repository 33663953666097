<div class="title">
  Dit product is afkomstig uit de <strong>{{ part }}</strong> van het varken
</div>
<div class="img">
  <svg
    id="Laag_1"
    data-name="Laag 1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 738 418"
  >
    <line class="cls-1" x1="475.21" y1="334.54" x2="455.21" y2="304.54" />
    <path
      class="cls-1"
      d="M151.47,272.41l1.43,134.5a86.3,86.3,0,0,1-21.42,0C65.81,207,24.4,268.41,41.53,128.13S185.74,2.85,407,32.83,669.76,223.44,669.76,223.44s57.11-6.43,58.53,59.25c-17.13,1.43-42.83,1.43-42.83,1.43S654.05,327,696.88,362.65c-17.13,17.13-55.68,61.39-164.19-20,1.43,12.85,12.85,64.88,12.85,64.88H524.12l-48.91-73"
    />
    <path class="cls-1" d="M151.81,308.4c11.33,4.49,22.84,8.4,34.4,11.76h0" />
    <path
      class="cls-1"
      d="M245.61,333.23h0c95.6,14.87,180,1.21,180,1.21v73.09h20L475,334.45"
    />
    <polyline
      class="cls-1"
      points="245.62 333.23 245.62 407.54 224.29 407.54 186.22 320.16"
    />
    <path
      class="cls-1"
      d="M44.66,95.12s-56-11.23-28.59-29.83c20.06-13.61,11.2,34.37,11.2,34.37"
    />
    <path class="cls-1" d="M425.21,334.54s17-2,48-1" />
    <path class="cls-1" d="M181.21,318.54s30,9,74,16" />
    <path
      class="cls-2"
      [ngClass]="{ selected: part == 'ham' }"
      d="M73.71,51c36-5,95,3,129,60,18,37,49,148-51,162-21,3-82-1-87-12-7.84-17.24-26.33-38.92-27-84C37.37,154.68,38.21,89.54,73.71,51Z"
    />
    <path
      class="cls-2"
      [ngClass]="{ selected: part == 'poot' }"
      d="M64.21,260.54l40,73s26,6,48-1c1-13-1-60-1-60S84.21,278.54,64.21,260.54Z"
    />
    <path
      class="cls-2"
      [ngClass]="{ selected: part == 'poot' }"
      d="M189.21,319.54l57,13v28c0,7-43-4-43-4S200.21,348.54,189.21,319.54Z"
    />
    <path
      class="cls-2"
      [ngClass]="{ selected: part == 'buik' }"
      d="M220.07,164.36s86.14,35.18,106.14.18c27,3,89,11,89,11s-26,92,33,122c19,28,22,36,22,36s-56,2-110,8c-29.82,3.32-81-3-114-9s-75-18-75-18l-18.12-4.28-1.88-37.72S239.21,268.54,220.07,164.36Z"
    />
    <path
      class="cls-2"
      [ngClass]="{ selected: part == 'varkenshaas' }"
      d="M220.21,164.54S217.4,148,212.3,134.76c16.81-3.43,83.91-20.22,113.91,29.78C306.21,194.54,245.21,172.54,220.21,164.54Z"
    />
    <path
      class="cls-2"
      [ngClass]="{ selected: part == 'karbonade' }"
      d="M212.21,134.54s-9-44.28-57-69.14c30-.71,174-3.86,283,23.14-4,8-16,32-18,79l-5,8-89-11S304.21,112.54,212.21,134.54Z"
    />
    <path
      class="cls-2"
      [ngClass]="{ selected: part == 'hals' }"
      d="M438.21,88.54s87,17,118,65a160.05,160.05,0,0,0-10,22c-11-30-88-57-126-8C422.21,143.54,425.21,111.54,438.21,88.54Z"
    />
    <path
      class="cls-2"
      [ngClass]="{ selected: part == 'rug' }"
      d="M556.21,153.54s25-33,47-30c-12-14-69-67-144-80s-239.64-38.11-298-28c-75,13-88,35-88,35s46-6,82,15c28-1,165-5,283,23C498.38,102.82,537.21,125.54,556.21,153.54Z"
    />
    <path
      class="cls-2"
      [ngClass]="{ selected: part == 'schouder' }"
      d="M546.21,175.54c-15-36-93.21-52.42-126-8-25,59-4,116,28,130,27.24,11.92,60.19,4.65,76-8C544.21,273.54,566.88,225.17,546.21,175.54Z"
    />
    <path
      class="cls-2"
      [ngClass]="{ selected: part == 'poot' }"
      d="M448.21,297.54l47,63s20,1,39-6c-4-18-27.76-45.46-10-65C509.21,297.54,489.21,310.54,448.21,297.54Z"
    />
    <path
      class="cls-2"
      [ngClass]="{ selected: part == 'poot' }"
      d="M425.87,336.48l.34,30.06s14,9,36,3c6-14,12.29-37.29,12.29-37.29Z"
    />
    <path
      class="cls-2"
      [ngClass]="{ selected: part == 'kop' }"
      d="M603.21,123.54s-36-3-57,52c18,41,6,87-22,114-18,18,9.5,53.94,9.5,53.94s111.5,88.06,162.5,19.06c-25-18-30-58-9-78,21,0,42-1,42-1s-1-66-58-59C659.21,190.54,619.21,127.54,603.21,123.54Z"
    />
    <text class="cls-3" transform="translate(231.76 151.95)">
      V
      <tspan class="cls-4" x="8.58" y="0">ARKENS</tspan>
      <tspan class="cls-4">
        <tspan x="13.58" y="14.4">HAAS</tspan>
      </tspan>
    </text>
    <path
      d="M274.23,250.35H260.35V201.7q8.86-.42,12.79-.43,7.2,0,11.15,3.11t4,8.88a9,9,0,0,1-2.53,6.17,10.78,10.78,0,0,1-5.58,3.49q5.79,1.4,8.42,4.62t2.64,8.76a12.52,12.52,0,0,1-4.7,10.23Q281.81,250.34,274.23,250.35ZM267,207v14c1.39.14,3.12.2,5.18.2q9.42,0,9.42-7.67,0-6.74-8.66-6.74Q269.61,206.78,267,207Zm0,19.12V244.7c2.27.22,4,.33,5.24.33q6.24,0,9.12-2.34t2.87-7.45c0-3.17-.92-5.5-2.74-7s-4.88-2.26-9.15-2.26Z"
    />
    <path
      d="M319.43,201.7h6.64V235a9.76,9.76,0,0,0,10.29,10.19,10.56,10.56,0,0,0,7.74-2.79,10.16,10.16,0,0,0,2.82-7.57V201.7h6.64v33.84q0,7.36-4.6,11.5t-12.53,4.14q-8.18,0-12.59-4.06t-4.41-11.62Z"
    />
    <path d="M272.46,315.35V266.7h6.64v48.65Z" />
    <path
      d="M346.24,315.35,332.52,293l-6.47,9.19v13.19h-6.64V266.7h6.64v26.47l18.06-26.47h7.41L337,287.79l16.6,27.56Z"
    />
    <path
      d="M570.39,234.25l27.83-17.62-12.9-5.27-17.36,2-.8-6.86L631.24,199l.8,6.86L597.18,210l37,14.6.89,7.65-29.53-11.78-34.3,21.39Z"
    />
    <path
      d="M605.58,321.51l-24.67,2.88-.8-6.86,64.09-7.48c.89,5.16,1.41,8.28,1.53,9.35q2.57,21.9-16.12,24.07Q608,346,605.74,326.64C605.65,325.86,605.6,324.15,605.58,321.51Zm31.54-3.68-23.66,2.76c0,2.94.08,4.49.1,4.65Q615,338,627.86,336.51q11.73-1.36,10.14-15A22.28,22.28,0,0,0,637.12,317.83Z"
    />
    <path
      d="M610.75,255.71Q622,254.41,630,257.93T639,269a14.58,14.58,0,0,1-5.44,13.75q-6.42,5.31-18.84,6.76Q602,291,594.21,287.3a14.48,14.48,0,0,1-8.72-12.09q-.9-7.66,6.22-12.89T610.75,255.71Zm.68,5.81q-8.89,1-14.53,4.49t-5.06,8.46q.66,5.67,6.48,8t15.7,1.19q20-2.34,18.61-14-.6-5.15-6.28-7.21T611.43,261.52Z"
    />
    <line class="cls-5" x1="576.43" y1="260.96" x2="580.1" y2="292.38" />
    <path
      d="M444.31,192.91l1.29-4.17a8.31,8.31,0,0,0,2.49,1.55,6.89,6.89,0,0,0,2.66.63,4.24,4.24,0,0,0,3.34-1.45,5.44,5.44,0,0,0,1.26-3.7,7,7,0,0,0-.7-3.12,9.11,9.11,0,0,0-3.53-3.14l-2.1-1.25a9.14,9.14,0,0,1-3.73-3.76,12,12,0,0,1-1.06-5.24,9.24,9.24,0,0,1,2.07-6.17,6.61,6.61,0,0,1,5.3-2.45c2.88,0,4.89.6,6,1.79l-1.05,3.95a7.51,7.51,0,0,0-2.17-1.27,7,7,0,0,0-2.7-.61,3.47,3.47,0,0,0-2.86,1.31,5.32,5.32,0,0,0-1,3.38,6.84,6.84,0,0,0,.38,2.32,4.72,4.72,0,0,0,1,1.74,14.44,14.44,0,0,0,2.73,2l2.13,1.3a9.29,9.29,0,0,1,3.75,3.84,13.2,13.2,0,0,1,1.07,5.68,9.12,9.12,0,0,1-2.35,6.35,8.09,8.09,0,0,1-6.31,2.61A8.78,8.78,0,0,1,444.31,192.91Z"
    />
    <path
      d="M485.65,162.43l-1.19,3.88c-1.14-1.06-2.93-1.58-5.38-1.58-2.28,0-4.12,1.25-5.5,3.76a20.06,20.06,0,0,0-2.07,9.71,17.59,17.59,0,0,0,2.12,9.19q2.13,3.53,5.49,3.53a6.25,6.25,0,0,0,5.64-3.31l2,3.47a9.72,9.72,0,0,1-8,3.92,9,9,0,0,1-8-4.69q-2.94-4.69-2.94-12.39c0-4.95,1-9.08,3.13-12.36s4.79-4.92,8.1-4.92A10.94,10.94,0,0,1,485.65,162.43Z"
    />
    <path
      d="M511.77,194.43V178.38H500.38v16.05h-3.56V161.21h3.56v13.31h11.39V161.21h3.55v33.22Z"
    />
    <path
      d="M472.36,205.8h3.56v22.75a8,8,0,0,0,1.51,5,4.82,4.82,0,0,0,4,2,5,5,0,0,0,4.15-1.91,8.08,8.08,0,0,0,1.51-5.17V205.8h3.56v23.11q0,5-2.46,7.86a8.48,8.48,0,0,1-6.72,2.82,8.35,8.35,0,0,1-6.74-2.77c-1.58-1.84-2.37-4.49-2.37-7.93Z"
    />
    <path
      d="M507.25,239H502V205.8q5-.23,6.42-.23a10.27,10.27,0,0,1,8.65,4.23q3.18,4.23,3.18,11.26Q520.25,239,507.25,239Zm-1.69-29.14v24.83a16.19,16.19,0,0,0,2.95.23,6.56,6.56,0,0,0,5.87-3.57q2.13-3.57,2.14-9.92,0-11.79-8.31-11.8C507.88,209.65,507,209.73,505.56,209.88Z"
    />
    <path
      d="M463.26,254.47v9.23h8.61v3.86h-8.61v12h11.81v4.08H459.7V250.39h15.57v4.08Z"
    />
    <path
      d="M499.69,283.61l-6.95-14.26c-.8,0-2.05-.08-3.77-.23v14.49h-3.56V250.39l2.76-.17c1.72-.12,3-.17,3.75-.17q9.39,0,9.39,9.52a10.68,10.68,0,0,1-1.51,5.64,6.47,6.47,0,0,1-3.59,3.12l7.56,15.28ZM489,254.47v10.79a13.42,13.42,0,0,0,2.49.23,6.88,6.88,0,0,0,4.66-1.31q1.45-1.32,1.46-4.7a4.76,4.76,0,0,0-1.57-4,8,8,0,0,0-4.94-1.23A18.63,18.63,0,0,0,489,254.47Z"
    />
    <path
      d="M443.23,220a20,20,0,0,1,2.41-10.26c1.61-2.74,3.78-4.12,6.51-4.12a7.94,7.94,0,0,1,7.07,3.8q2.44,3.8,2.45,10.58c0,4.63-.82,8.27-2.46,10.89a7.85,7.85,0,0,1-7.06,3.94c-2.79,0-5-1.38-6.55-4.16A21.37,21.37,0,0,1,443.23,220Zm3.17,0a19.38,19.38,0,0,0,1.51,8.11q1.5,3.26,4.24,3.26a5.13,5.13,0,0,0,4.72-3c1.09-2,1.63-4.79,1.63-8.38q0-10.92-6.35-10.91a4.62,4.62,0,0,0-4.28,2.93A18,18,0,0,0,446.4,220Z"
    />
    <line class="cls-6" x1="443.9" y1="238.84" x2="461.06" y2="238.84" />
    <text class="cls-7" transform="translate(221.54 56.51) rotate(0.96)">
      R
    </text>
    <text class="cls-7" transform="translate(261.17 57.37) rotate(2.19)">
      U
    </text>
    <text class="cls-7" transform="matrix(1, 0.08, -0.08, 1, 302.74, 59.26)">
      G
    </text>
    <text
      class="cls-7"
      transform="matrix(0.99, 0.13, -0.13, 0.99, 344.91, 63.22)"
    >
      S
    </text>
    <text class="cls-7" transform="translate(380.95 68.35) rotate(9.63)">
      P
    </text>
    <text class="cls-7" transform="translate(419.05 75.3) rotate(12.39)">
      E
    </text>
    <text class="cls-7" transform="translate(455.95 84.04) rotate(16.31)">
      K
    </text>
    <path
      d="M250.26,92.4,246.61,86l-1.88,2.52-.11,3.68-1.85,0,.39-13.59,1.85.05L244.8,86l5.26-7.24,2.06,0-4.23,5.78,4.42,7.83Z"
    />
    <path
      d="M265.76,93l-.82-2.91-5.06-.2-1.11,2.82-2.08-.08,6.07-13.54.49,0,4.56,14Zm-3-10.35-2.34,5.9,4,.16Z"
    />
    <path
      d="M281.08,93.71l-3.33-6c-.41,0-1.06-.08-2-.19l-.3,5.92-1.85-.09.69-13.58h1.44c.9,0,1.55,0,2,0q4.89.24,4.69,4.14a3.67,3.67,0,0,1-.9,2.26,3.32,3.32,0,0,1-1.94,1.18l3.62,6.44Zm-5-12.19-.23,4.41a8.7,8.7,0,0,0,1.3.16,4.26,4.26,0,0,0,2.45-.41,2.19,2.19,0,0,0,.86-1.88,1.78,1.78,0,0,0-.73-1.68,5.13,5.13,0,0,0-2.55-.63C277,81.48,276.64,81.49,276.1,81.52Z"
    />
    <path
      d="M292.8,94.38l-3.87-.23.82-13.57c1.66,0,2.85,0,3.58.1a4.94,4.94,0,0,1,3.05,1,3,3,0,0,1,1,2.55,2.53,2.53,0,0,1-.81,1.68,3,3,0,0,1-1.62.87,4.27,4.27,0,0,1,2.27,1.43,3.74,3.74,0,0,1,.59,2.49,3.51,3.51,0,0,1-1.48,2.78A5.29,5.29,0,0,1,292.8,94.38Zm-1.61-6.87-.31,5.18c.63.1,1.11.16,1.45.18a4,4,0,0,0,2.59-.5,2.5,2.5,0,0,0,.92-2,2.38,2.38,0,0,0-.64-2,4.12,4.12,0,0,0-2.52-.79Zm.33-5.33-.24,3.9c.39.06.87.1,1.44.14,1.75.1,2.67-.55,2.76-2,.08-1.25-.69-1.93-2.3-2C292.56,82.18,292,82.16,291.52,82.18Z"
    />
    <path
      d="M329.83,97.25l-6.19-10.47-.8,9.72-1.76-.15,1.12-13.54.74.06,6,9.92.77-9.36,1.76.14L330.38,97.3Z"
    />
    <path
      d="M345.18,98.46l-.67-2.95-5-.47-1.26,2.77-2.07-.2,6.77-13.2.49,0,3.82,14.2ZM342.75,88l-2.65,5.76,4,.38Z"
    />
    <path
      d="M355.69,99.5,353,99.22l1.42-13.52c1.74.12,2.85.21,3.34.26a5.91,5.91,0,0,1,4.3,2.19,6.36,6.36,0,0,1,1.17,4.75Q362.44,100.2,355.69,99.5Zm.37-12L355,97.66a10.92,10.92,0,0,0,1.52.25,3.63,3.63,0,0,0,3.2-1.13,6.59,6.59,0,0,0,1.53-3.93q.5-4.78-3.81-5.24Q357.18,87.58,356.06,87.55Z"
    />
    <path
      d="M372.08,89.3l-.43,3.75,4.46.52-.19,1.56-4.46-.51-.56,4.86,6.12.71-.2,1.66-8-.92,1.57-13.5,8.07.93L378.3,90Z"
    />
    <path
      d="M305.9,87a8.45,8.45,0,0,1,1.37-4.23,3.08,3.08,0,0,1,2.88-1.5A3.35,3.35,0,0,1,313,83.11a8.15,8.15,0,0,1,.66,4.53,8.59,8.59,0,0,1-1.42,4.5,3.33,3.33,0,0,1-3.1,1.41,3.08,3.08,0,0,1-2.61-2A9.13,9.13,0,0,1,305.9,87Zm1.33.11a8.29,8.29,0,0,0,.35,3.46,2,2,0,0,0,1.67,1.52A2.16,2.16,0,0,0,311.34,91a7.5,7.5,0,0,0,1-3.47c.25-3-.51-4.66-2.29-4.8a1.93,1.93,0,0,0-1.9,1.08A7.6,7.6,0,0,0,307.23,87.11Z"
    />
    <line class="cls-8" x1="305.52" y1="94.93" x2="312.74" y2="95.53" />
    <text class="cls-9" transform="translate(252.53 120.9) rotate(3.61)">
      STRENG
    </text>
    <text class="cls-10" transform="translate(454.73 131.48) rotate(-90)">
      H
      <tspan class="cls-11">
        <tspan x="1.08" y="18">A</tspan>
      </tspan>
      <tspan class="cls-12">
        <tspan x="1.67" y="36">L</tspan>
      </tspan>
      <tspan class="cls-13">
        <tspan x="1.56" y="54">S</tspan>
      </tspan>
    </text>
    <text class="cls-14" transform="translate(101.28 126.5)">
      H
      <tspan class="cls-15">
        <tspan x="3.35" y="55">A</tspan>
      </tspan>
      <tspan x="-1.54" y="110">M</tspan>
    </text>
    <path
      d="M104,297.47h-2v-7.15c.87,0,1.5-.06,1.88-.06a2.6,2.6,0,0,1,1.64.45A1.57,1.57,0,0,1,106,292a1.34,1.34,0,0,1-.38.91,1.58,1.58,0,0,1-.82.51,2.21,2.21,0,0,1,1.24.68,2,2,0,0,1,.39,1.29,1.82,1.82,0,0,1-.69,1.5A2.73,2.73,0,0,1,104,297.47Zm-1.07-6.37v2.06c.21,0,.46,0,.76,0,.93,0,1.39-.38,1.39-1.13s-.43-1-1.28-1Q103.3,291.07,102.91,291.1Zm0,2.81v2.73c.34,0,.59.05.77.05a2.11,2.11,0,0,0,1.34-.34,1.35,1.35,0,0,0,.43-1.1,1.26,1.26,0,0,0-.41-1,2.14,2.14,0,0,0-1.34-.33Z"
    />
    <path d="M120.82,297.49h-.49l-2.67-7.25h1.09l1.84,5.27,1.77-5.27h1Z" />
    <path
      d="M127.18,291.17v2h2.37V294h-2.37v2.58h3.24v.88h-4.21v-7.15h4.27v.88Z"
    />
    <path
      d="M138.19,297.49l-3.7-5.23v5.13h-.93v-7.15H134l3.6,4.94v-4.94h.93v7.25Z"
    />
    <path
      d="M106.58,310.71v2.76h-1v-7.16l1.34-.05c2.08,0,3.11.7,3.11,2.09,0,1.61-.91,2.41-2.75,2.41Zm0-3.52v2.64l.65.05c1.21,0,1.82-.48,1.82-1.43s-.65-1.31-1.94-1.31A4.34,4.34,0,0,0,106.58,307.19Z"
    />
    <path d="M132.26,307.17v6.27h-1v-6.27H129v-.88h5.63v.88Z" />
    <path
      d="M113.34,309.4a4.23,4.23,0,0,1,.5-2.14,1.52,1.52,0,0,1,1.36-.86,1.64,1.64,0,0,1,1.48.79,4.08,4.08,0,0,1,.51,2.21,4.33,4.33,0,0,1-.51,2.28,1.65,1.65,0,0,1-1.48.82,1.52,1.52,0,0,1-1.37-.87A4.46,4.46,0,0,1,113.34,309.4Zm.66,0a4,4,0,0,0,.32,1.69,1,1,0,0,0,.88.69,1.08,1.08,0,0,0,1-.63,3.65,3.65,0,0,0,.34-1.75c0-1.52-.44-2.28-1.33-2.28a1,1,0,0,0-.89.61A3.85,3.85,0,0,0,114,309.4Z"
    />
    <line class="cls-16" x1="113.48" y1="313.33" x2="117.06" y2="313.33" />
    <path
      d="M121.62,309.4a4.13,4.13,0,0,1,.51-2.14,1.49,1.49,0,0,1,1.35-.86,1.64,1.64,0,0,1,1.48.79,4.08,4.08,0,0,1,.51,2.21,4.33,4.33,0,0,1-.51,2.28,1.64,1.64,0,0,1-1.48.82,1.51,1.51,0,0,1-1.36-.87A4.46,4.46,0,0,1,121.62,309.4Zm.66,0a4,4,0,0,0,.32,1.69,1,1,0,0,0,.88.69,1.06,1.06,0,0,0,1-.63,3.65,3.65,0,0,0,.34-1.75c0-1.52-.44-2.28-1.33-2.28a1,1,0,0,0-.89.61A3.85,3.85,0,0,0,122.28,309.4Z"
    />
    <line class="cls-16" x1="121.76" y1="313.33" x2="125.35" y2="313.33" />
    <path
      d="M110.12,293.4a4.13,4.13,0,0,1,.51-2.14,1.51,1.51,0,0,1,1.36-.86,1.64,1.64,0,0,1,1.47.79,4,4,0,0,1,.52,2.21,4.32,4.32,0,0,1-.52,2.28,1.64,1.64,0,0,1-1.47.82,1.51,1.51,0,0,1-1.37-.87A4.46,4.46,0,0,1,110.12,293.4Zm.67,0a4,4,0,0,0,.31,1.69,1,1,0,0,0,.89.68,1,1,0,0,0,1-.62,3.67,3.67,0,0,0,.34-1.75q0-2.28-1.32-2.28a1,1,0,0,0-.9.61A3.87,3.87,0,0,0,110.79,293.4Z"
    />
    <line class="cls-16" x1="110.26" y1="297.33" x2="113.85" y2="297.33" />
    <path
      d="M207.81,338.64l-1.73-.24.84-6.06c.74.07,1.28.12,1.6.17a2.17,2.17,0,0,1,1.34.58,1.35,1.35,0,0,1,.34,1.17,1.17,1.17,0,0,1-.42.73,1.38,1.38,0,0,1-.76.33,2,2,0,0,1,1,.72,1.73,1.73,0,0,1,.18,1.14,1.58,1.58,0,0,1-.77,1.19A2.35,2.35,0,0,1,207.81,338.64Zm-.15-5.52-.24,1.74a5.55,5.55,0,0,0,.64.11c.78.11,1.22-.15,1.3-.79s-.24-.89-1-1A7.3,7.3,0,0,0,207.66,333.12Zm-.33,2.38-.32,2.31a6,6,0,0,0,.64.13,1.85,1.85,0,0,0,1.18-.13,1.17,1.17,0,0,0,.49-.88,1.09,1.09,0,0,0-.22-.92,1.82,1.82,0,0,0-1.1-.44Z"
    />
    <path
      d="M222.08,340.63l-.42-.05-1.41-6.46.92.13.94,4.68,2.12-4.26.89.13Z"
    />
    <path
      d="M228.21,336l-.24,1.68,2,.28-.1.7-2-.28-.31,2.19,2.75.38-.1.74-3.58-.49.84-6.06,3.62.5-.1.74Z"
    />
    <path
      d="M236.78,342.68l-2.51-4.87-.61,4.35-.78-.11.84-6.06.33.05,2.47,4.61.58-4.19.78.11-.85,6.14Z"
    />
    <path
      d="M208.46,350.16l-.33,2.33-.82-.12.84-6.05,1.14.11c1.75.25,2.55,1,2.39,2.13-.19,1.37-1.06,1.94-2.62,1.72Zm.41-3-.31,2.23.55.12c1,.14,1.59-.19,1.7-1s-.39-1.18-1.48-1.33A2.08,2.08,0,0,0,208.87,347.18Z"
    />
    <path
      d="M230.63,350.18l-.74,5.31-.83-.12.74-5.31-1.93-.27.11-.74,4.76.66-.1.75Z"
    />
    <path
      d="M214.34,349.84a3.47,3.47,0,0,1,.68-1.75,1.29,1.29,0,0,1,1.25-.57,1.41,1.41,0,0,1,1.16.84,3.54,3.54,0,0,1,.17,1.93,3.63,3.63,0,0,1-.7,1.87,1.42,1.42,0,0,1-1.35.53,1.32,1.32,0,0,1-1.06-.9A3.82,3.82,0,0,1,214.34,349.84Zm.56.08a3.34,3.34,0,0,0,.07,1.47c.12.41.34.64.67.68a.91.91,0,0,0,.91-.41,3.23,3.23,0,0,0,.49-1.44c.18-1.29-.11-2-.86-2.09a.81.81,0,0,0-.82.41A3.11,3.11,0,0,0,214.9,349.92Z"
    />
    <line class="cls-17" x1="213.99" y1="353.19" x2="217.03" y2="353.61" />
    <path
      d="M221.35,350.82a3.6,3.6,0,0,1,.68-1.76,1.29,1.29,0,0,1,1.25-.57,1.42,1.42,0,0,1,1.16.85,3.43,3.43,0,0,1,.18,1.93,3.71,3.71,0,0,1-.71,1.87,1.39,1.39,0,0,1-1.34.52,1.3,1.3,0,0,1-1.06-.9A3.89,3.89,0,0,1,221.35,350.82Zm.56.07a3.54,3.54,0,0,0,.07,1.48.86.86,0,0,0,.67.68.91.91,0,0,0,.91-.42,3,3,0,0,0,.49-1.44c.18-1.29-.1-2-.85-2.09a.85.85,0,0,0-.83.42A3.33,3.33,0,0,0,221.91,350.89Z"
    />
    <line class="cls-17" x1="221.01" y1="354.16" x2="224.05" y2="354.58" />
    <path
      d="M213.5,335.91a3.56,3.56,0,0,1,.67-1.75,1.3,1.3,0,0,1,1.26-.57,1.38,1.38,0,0,1,1.15.84,3.44,3.44,0,0,1,.18,1.93,3.67,3.67,0,0,1-.7,1.87,1.42,1.42,0,0,1-1.35.53,1.31,1.31,0,0,1-1.06-.9A3.82,3.82,0,0,1,213.5,335.91Zm.56.08a3.51,3.51,0,0,0,.06,1.47c.13.41.35.64.67.68a.9.9,0,0,0,.91-.41,3.23,3.23,0,0,0,.5-1.44c.18-1.29-.11-2-.86-2.09a.82.82,0,0,0-.83.41A3.19,3.19,0,0,0,214.06,336Z"
    />
    <line class="cls-17" x1="213.15" y1="339.26" x2="216.19" y2="339.68" />
    <path
      d="M434.63,347.57H433v-5.81c.7,0,1.21-.05,1.52-.05a2.08,2.08,0,0,1,1.33.37,1.26,1.26,0,0,1,.47,1.06,1.08,1.08,0,0,1-.3.74,1.24,1.24,0,0,1-.66.41,1.88,1.88,0,0,1,1,.55,1.62,1.62,0,0,1,.32,1.05,1.5,1.5,0,0,1-.56,1.22A2.31,2.31,0,0,1,434.63,347.57Zm-.86-5.17v1.66a5.22,5.22,0,0,0,.62,0c.75,0,1.12-.31,1.12-.92s-.34-.8-1-.8C434.21,342.37,434,342.38,433.77,342.4Zm0,2.28v2.21a5.82,5.82,0,0,0,.62,0,1.7,1.7,0,0,0,1.09-.28,1.06,1.06,0,0,0,.34-.89,1,1,0,0,0-.32-.83,1.74,1.74,0,0,0-1.09-.27Z"
    />
    <path d="M448.3,347.58h-.4l-2.16-5.88h.88l1.49,4.27,1.44-4.27h.85Z" />
    <path
      d="M453.46,342.45v1.61h1.92v.68h-1.92v2.09h2.63v.71h-3.42v-5.8h3.46v.71Z"
    />
    <path d="M462.39,347.58l-3-4.24v4.16h-.75v-5.8H459l2.92,4v-4h.76v5.88Z" />
    <path
      d="M436.74,358.31v2.23H436v-5.8l1.09,0c1.68,0,2.52.56,2.52,1.69,0,1.31-.74,2-2.23,2Zm0-2.86v2.15l.53,0c1,0,1.48-.39,1.48-1.17s-.53-1.06-1.58-1.06Z"
    />
    <path d="M457.58,355.43v5.09h-.79v-5.09h-1.85v-.71h4.57v.71Z" />
    <path
      d="M442.23,357.24a3.38,3.38,0,0,1,.41-1.73,1.23,1.23,0,0,1,1.1-.7,1.34,1.34,0,0,1,1.2.64,3.27,3.27,0,0,1,.42,1.79,3.45,3.45,0,0,1-.42,1.85,1.33,1.33,0,0,1-1.2.67,1.24,1.24,0,0,1-1.11-.71A3.59,3.59,0,0,1,442.23,357.24Zm.54,0a3.31,3.31,0,0,0,.25,1.38.79.79,0,0,0,.72.55.87.87,0,0,0,.8-.5,3,3,0,0,0,.28-1.43c0-1.23-.36-1.84-1.08-1.84a.76.76,0,0,0-.72.49A3,3,0,0,0,442.77,357.24Z"
    />
    <line class="cls-18" x1="442.34" y1="360.43" x2="445.25" y2="360.43" />
    <path
      d="M449,357.24a3.28,3.28,0,0,1,.41-1.73,1.23,1.23,0,0,1,1.1-.7,1.34,1.34,0,0,1,1.2.64,3.27,3.27,0,0,1,.41,1.79,3.45,3.45,0,0,1-.41,1.85,1.33,1.33,0,0,1-1.2.67,1.24,1.24,0,0,1-1.11-.71A3.59,3.59,0,0,1,449,357.24Zm.54,0a3.31,3.31,0,0,0,.25,1.38.79.79,0,0,0,.72.55.87.87,0,0,0,.8-.5,3.1,3.1,0,0,0,.28-1.43c0-1.23-.36-1.84-1.08-1.84a.79.79,0,0,0-.73.49A3.14,3.14,0,0,0,449.49,357.24Z"
    />
    <line class="cls-18" x1="449.06" y1="360.43" x2="451.97" y2="360.43" />
    <path
      d="M439.62,344.26a3.45,3.45,0,0,1,.41-1.74,1.24,1.24,0,0,1,1.1-.69,1.34,1.34,0,0,1,1.2.64,3.27,3.27,0,0,1,.42,1.79,3.47,3.47,0,0,1-.42,1.85,1.35,1.35,0,0,1-1.2.67,1.24,1.24,0,0,1-1.11-.71A3.59,3.59,0,0,1,439.62,344.26Zm.54,0a3.31,3.31,0,0,0,.25,1.38.79.79,0,0,0,.72.55.86.86,0,0,0,.8-.51,2.9,2.9,0,0,0,.28-1.42c0-1.23-.36-1.85-1.08-1.85a.77.77,0,0,0-.72.5A3,3,0,0,0,440.16,344.26Z"
    />
    <line class="cls-18" x1="439.73" y1="347.45" x2="442.64" y2="347.45" />
    <path
      d="M480.81,324.94l-1.91.57-2-6.7c.8-.28,1.38-.47,1.74-.58a2.56,2.56,0,0,1,1.66,0,1.53,1.53,0,0,1,.91,1.06,1.3,1.3,0,0,1-.1,1,1.56,1.56,0,0,1-.62.71,2.11,2.11,0,0,1,1.34.29,2,2,0,0,1,.73,1.1,1.83,1.83,0,0,1-.23,1.6A2.67,2.67,0,0,1,480.81,324.94Zm-2.76-5.67.57,1.93a7.21,7.21,0,0,0,.72-.19c.86-.25,1.19-.73,1-1.44s-.67-.81-1.46-.57Q478.4,319.14,478.05,319.27Zm.78,2.64.75,2.55a7.19,7.19,0,0,0,.74-.17,2.09,2.09,0,0,0,1.16-.69,1.29,1.29,0,0,0,.09-1.14,1.26,1.26,0,0,0-.66-.86,2.07,2.07,0,0,0-1.35.07Z"
    />
    <path d="M496.59,320.28l-.46.14-4.51-6.05,1-.3,3.18,4.42.2-5.42,1-.3Z" />
    <path
      d="M500.79,312.6l.55,1.86,2.22-.66.23.78-2.22.65.72,2.42,3-.9.24.82-3.95,1.17-2-6.7,4-1.18.25.82Z"
    />
    <path
      d="M512.85,315.46l-4.91-3.87,1.42,4.81-.87.25-2-6.69.36-.11,4.75,3.63-1.38-4.63.87-.26,2,6.79Z"
    />
    <path
      d="M486.92,336.62l.77,2.58-.92.27-2-6.7,1.24-.42c1.94-.57,3.11-.21,3.49,1.09.45,1.51-.19,2.51-1.91,3C487.51,336.49,487.28,336.55,486.92,336.62Zm-1-3.3.73,2.48.63-.14c1.13-.34,1.56-1,1.3-1.84s-1-1.05-2.18-.69A5.12,5.12,0,0,0,486,333.32Z"
    />
    <path
      d="M510,326.17l1.74,5.87-.91.28-1.74-5.88-2.14.63-.24-.82,5.27-1.56.25.82Z"
    />
    <path
      d="M492.89,333.51a4.08,4.08,0,0,1-.12-2.14,1.5,1.5,0,0,1,1-1.19,1.65,1.65,0,0,1,1.61.34,4,4,0,0,1,1.09,1.92,4.18,4.18,0,0,1,.15,2.28,1.6,1.6,0,0,1-1.15,1.18,1.48,1.48,0,0,1-1.53-.44A4.43,4.43,0,0,1,492.89,333.51Zm.62-.18a4.06,4.06,0,0,0,.77,1.5.94.94,0,0,0,1,.39,1.06,1.06,0,0,0,.75-.86,3.67,3.67,0,0,0-.17-1.73c-.42-1.42-1.05-2-1.88-1.77a1,1,0,0,0-.66.82A3.73,3.73,0,0,0,493.51,333.33Z"
    />
    <line class="cls-19" x1="494.12" y1="337.16" x2="497.47" y2="336.16" />
    <path
      d="M500.65,331.21a4.09,4.09,0,0,1-.13-2.14,1.51,1.51,0,0,1,1-1.19,1.64,1.64,0,0,1,1.6.34,4,4,0,0,1,1.1,1.92,4.38,4.38,0,0,1,.15,2.28,1.61,1.61,0,0,1-1.16,1.18,1.48,1.48,0,0,1-1.52-.43A4.43,4.43,0,0,1,500.65,331.21Zm.62-.18a4.06,4.06,0,0,0,.76,1.5,1,1,0,0,0,1,.39,1,1,0,0,0,.75-.86,3.55,3.55,0,0,0-.17-1.73c-.42-1.42-1-2-1.87-1.77a1,1,0,0,0-.67.82A3.61,3.61,0,0,0,501.27,331Z"
    />
    <line class="cls-19" x1="501.87" y1="334.86" x2="505.23" y2="333.86" />
    <path
      d="M485.44,319.42a4,4,0,0,1-.12-2.14,1.5,1.5,0,0,1,1-1.19,1.63,1.63,0,0,1,1.6.34,4,4,0,0,1,1.1,1.92,4.18,4.18,0,0,1,.15,2.28,1.61,1.61,0,0,1-1.16,1.18,1.47,1.47,0,0,1-1.52-.44A4.33,4.33,0,0,1,485.44,319.42Zm.62-.18a4.06,4.06,0,0,0,.76,1.5,1,1,0,0,0,1,.39,1,1,0,0,0,.75-.86,3.65,3.65,0,0,0-.16-1.73c-.43-1.42-1.05-2-1.88-1.77a.94.94,0,0,0-.67.82A3.75,3.75,0,0,0,486.06,319.24Z"
    />
    <line class="cls-19" x1="486.66" y1="323.07" x2="490.02" y2="322.07" />
  </svg>
</div>

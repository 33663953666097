import { Injectable } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';
import { AuthenticationService } from './authentication.service';

@Injectable()
export class PwaService {
    public promptEvent: any;

    constructor(
        private swUpdate: SwUpdate,
        private authService: AuthenticationService) {
        window.addEventListener('beforeinstallprompt', event => {
            this.promptEvent = event;
        });
    }

    public handleUpdate() {
        if (this.swUpdate.isEnabled) {
            this.swUpdate.available.subscribe(() => {
                this.authService.logout();
                console.log("Er is op de achtergrond een nieuwe versie geladen");
                setTimeout(() => {
                    window.location.reload();
                }, 1000);
            });
        }
    }

    public addToHomeScreen() {
        this.promptEvent.prompt();
    }
}
<div class="header">
  <img lazyLoad="/assets/images/account-banner.jpg" />
  <div class="overlay">
    <p class="title main-title">
      Het is altijd tijd voor echt Texels lamsvlees!
    </p>
    <p class="title sub-title">
      Eerlijk, heerlijk en verantwoord lamsvlees van Texel
    </p>
  </div>
  <div class="overlay-button">
    <button class="button" routerLink="bestellen">
      Bestel nu jouw favorieten
    </button>
  </div>
</div>
<div class="sub-header">
  <div class="item-container">
    <div class="item anim1">
      <i class="goenga-icon icon-truck"></i>
      <p>VOOR 12:00 BESTELD, VOLGENDE DAG IN HUIS</p>
    </div>
    <div class="item anim2">
      <i class="goenga-icon icon-tag"></i>
      <p>VERS EN TRACEERBAAR ECHT TEXELS VLEES</p>
    </div>
    <div class="item anim3">
      <i class="goenga-icon icon-box"></i>
      <p>GEKOELD VERSTUURD DOOR HEEL NEDERLAND</p>
    </div>
  </div>
</div>

<div class="title">
  Dit product is afkomstig uit de <strong>{{ part }}</strong> van het rund
</div>
<div class="img">
  <svg
    id="Laag_1"
    data-name="Laag 1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 738 502"
  >
    <path
      class="cls-1"
      [ngClass]="{ selected: part == 'ossenhaas' }"
      d="M268.79,150.39c11.14-49.17-3.52-75.5-3.52-75.5L54.14,65.17C46.33,79.3,40.75,97,38.94,119.1c.16.08,32.07,17,92.43,26.65C167.32,151.53,213.36,154.76,268.79,150.39Z"
    />
    <path
      class="cls-1"
      [ngClass]="{ selected: part == 'dunnelende' }"
      d="M220,23h0c-22.62-2.1-46.59-4.7-72.26-8a49.8,49.8,0,0,0-6.06-.61c-21,1.82-42.4,7.31-59.78,19a97.33,97.33,0,0,0-27.8,31.76l211.13,9.72S249.84,34.26,220,23Z"
    />
    <path
      class="cls-1"
      [ngClass]="{ selected: part == 'bil' }"
      d="M113.74,142.58l31.47,68.75-41.46,39.49L44.51,283.4s8.15-29.54-.37-91.28-5.55-72.6-5.55-72.6S81.29,138,113.74,142.58Z"
    />
    <path
      class="cls-1"
      [ngClass]="{ selected: part == 'schenkel' }"
      d="M103.75,250.82,44.51,283.4s-12.83,37.51,0,54.29c0,16.79.48,37.3.48,37.3s15,8,41.33-.32c8.2-16.7,42.11-100.16,42.11-100.16S116.58,266.62,103.75,250.82Z"
    />
    <path
      class="cls-1"
      [ngClass]="{ selected: part == 'schenkel' }"
      d="M128.43,274.51s29.61,23.7,67.13,24.68c0,21.72-2,32.58-15.8,53.31l6.91,20.73s-14.81,7.9-39.49,6.91C142.25,371.26,128.43,274.51,128.43,274.51Z"
    />
    <path
      class="cls-1"
      [ngClass]="{ selected: part == 'spierstuk' }"
      d="M268.61,150.13c-1,6.91-11.85,40.47-21.72,60.22-4.94,10.85-27.64,62.19-51.33,88.84-18.76-2-64.17-8.88-91.81-48.37,9.87-8.88,41.46-39.49,41.46-39.49l-31.59-69.1s61.94,11.43,100.69,10.86C214.31,153.09,245.9,153.09,268.61,150.13Z"
    />
    <path
      class="cls-1"
      [ngClass]="{ selected: part == 'ribeye' }"
      d="M224.18,22.78l157.41,10.6S390,93.86,385.1,135.32c-9.88,2-36.42,4.46-121.87,33.32C268.06,152.34,296.25,74.11,224.18,22.78Z"
    />
    <path
      class="cls-2"
      [ngClass]="{ selected: part == 'vang' }"
      d="M385.1,135.32s-2,107.6-39.49,151c-13.82-3-115.5-11.85-150,12.83,27.64-35.54,56.26-93.78,68.11-130.31C285.39,162,357.45,137.29,385.1,135.32Z"
    />
    <path
      class="cls-1"
      [ngClass]="{ selected: part == 'klaprib' }"
      d="M385.1,135.32s27.1-3.46,36.26,7.65a233.2,233.2,0,0,0-11,68.26c-.26,34.76-4.57,79.08-21.36,95.86-6.91-5.92-39.48-19.74-43.43-20.73,4.93-4.94,22.29-27.27,32.22-85.15C380.16,187.64,385.1,159,385.1,135.32Z"
    />
    <path
      class="cls-1"
      [ngClass]="{ selected: part == 'dikkerib' }"
      d="M385.1,135.32s3.94-64.17-4-101.68c18.75,0,87.86-1,102.66,13.82,2,15.79,4.94,43.44-3.94,66.14-7.9,0-41.47-5.92-58.25,29.62C416.69,139.27,412.74,133.35,385.1,135.32Z"
    />
    <path
      class="cls-1"
      [ngClass]="{ selected: part == 'onderrib' }"
      d="M483.81,47.46s73.06,40.47,106.62,105.63c-7.9,4.93-51.34,25.57-51.34,25.57s13.83-62.1-59.22-65.06C483.81,100.77,489.74,79.05,483.81,47.46Z"
    />
    <path
      class="cls-1"
      [ngClass]="{ selected: part == 'schouder' }"
      d="M389,307.09s20.74-12.83,21.72-95.76,26.66-100.69,69.11-97.73S544,136.31,539.1,178.76c-5.73,49.27-42.45,110.56-61.21,159.92A395,395,0,0,0,389,307.09Z"
    />
    <path
      class="cls-1"
      [ngClass]="{ selected: part == 'schenkel' }"
      d="M477.89,338.68S402.86,304.13,389,312c1,12.83-2.22,74.48-2.22,74.48s20.89,9,42.7,10.41c3.77.25,2-5.92,4-13.82.8-3.2,2.34,13.6,5.92,15.8,11.53,7.07,28.95,8.09,42.45,5.92C477.89,368.3,477.89,338.68,477.89,338.68Z"
    />
    <path
      class="cls-1"
      [ngClass]="{ selected: part == 'puntborst' }"
      d="M524.11,233.83s-24.88,57.6-44.93,100a117.09,117.09,0,0,0,.24,16.92s14.27-20,36,1.77c28.63-25.67,48.38-12.83,48.38-12.83Z"
    />
    <path
      class="cls-1"
      [ngClass]="{ selected: part == 'hals' }"
      d="M539.1,178.76A177.44,177.44,0,0,1,524.29,234c7.9,20.73,39.49,105.63,39.49,105.63h-1s22.66-75.12,70.09-111.55c-8.79-24.5-42.45-75-42.45-75Z"
    />
    <path
      class="cls-1"
      [ngClass]="{ selected: part == 'kop' }"
      d="M628.8,446c4.16,1.59,22.84,22,22.84,22s38.5,29.62,44.42-25.66-2-80.95-3.95-84.9,14.81-21.72,14.81-21.72l-1.1-23.19c-.55-9.66-4.82-10.38,10.7-25.38l11.13-5.73s-24.68-23.69-55.28,0c-15.8-15.79-43.44-51.33-43.44-51.33-12.83,12.83-46.4,38.5-63.18,104.64,1,26.66,5.15,39.78,5.15,39.78l2.22,27.71S577.6,426.54,628.8,446Z"
    />
    <path
      class="cls-3"
      d="M728,281.3s-28.93-25-53.91,0c-26.29-13.15-58.64-88-64.42-98.61C598.54,162.21,530.9,41.11,440.06,35.43c-84.15-5.26-168.3-5.26-291.89-21C116.62,9.13,20.64,31.49,41.67,172.17,46.93,211.61,50.88,255,44.3,281.3s-10.51,39.44,0,56.53V452.22l-6.52,34.19H91.64L79.86,456.17l1.31-68.37,44.65-105.19s7.89,10.52,9.2,60.48c7.89,31.56,35.5,114.39,35.5,114.39l-2.63,28.93,62.38.94L210,452.91s-24.61-94-29.87-100.61c5.26-15.78,13.71-1.9,15-53.18,18.63-18.17,154.63-27,193.66,12.42,1.32,26.29-11.42,174.87-11.42,174.87H425.6l-11.05-32.87,18.93-71,21,55.22,2.63,48.65h64.43l-32.87-38.13-10.52-96a18.49,18.49,0,0,1,1.22-1.5s0-.05,0-.07a6.37,6.37,0,0,1,4.48-4.49l.94-.21c6.53-4.39,17.38-7.32,30.16,6.27,14.47-10.52,39.45-28.93,52.59-3.95,2.63,27.61,5.26,53.91,5.26,53.91s9.21,26.3,55.23,43.39c27.61,32.87,64.42,56.54,69.68-15.78-2.63-51.28,6.57-56.54-5.26-72.31C707,333.89,710.91,344.41,705.65,301,713.54,287.87,716.17,285.24,728,281.3Z"
    />
    <path class="cls-3" d="M299.71,284.23s-26.5,61.54-106.53,42.15" />
    <line class="cls-3" x1="235.37" y1="333.13" x2="235.37" y2="357.44" />
    <line class="cls-3" x1="210.02" y1="333.13" x2="210.02" y2="354.26" />
    <path class="cls-3" d="M113.74,17.48S4.86-8.83,7.49,203.94" />
    <line class="cls-3" x1="256.4" y1="326.4" x2="256.4" y2="351.7" />
    <text
      class="cls-4"
      transform="translate(96.09 51.1) rotate(2.33) scale(0.91 1.16)"
    >
      DUNNELENDE
    </text>
    <path
      d="M87.8,116.33l1-3.12a6.82,6.82,0,0,0,1.72,1.43,5.06,5.06,0,0,0,1.88.72,2.64,2.64,0,0,0,2.43-.86,4.32,4.32,0,0,0,1-2.84,6.34,6.34,0,0,0-.41-2.54,7.33,7.33,0,0,0-2.43-2.74l-1.46-1.12A7.51,7.51,0,0,1,89,102.08a10.17,10.17,0,0,1-.6-4.13,7.6,7.6,0,0,1,1.65-4.74,4.57,4.57,0,0,1,3.85-1.76c2.06.09,3.48.63,4.25,1.63l-.87,3.13a5.26,5.26,0,0,0-1.51-1.1,4.67,4.67,0,0,0-1.91-.6,2.34,2.34,0,0,0-2.08.94A4.37,4.37,0,0,0,91,98.06a6,6,0,0,0,.2,1.84,4.33,4.33,0,0,0,.69,1.43,12.47,12.47,0,0,0,1.9,1.71l1.48,1.15a7.64,7.64,0,0,1,2.57,3.31,11.92,11.92,0,0,1,.6,4.64,7.28,7.28,0,0,1-1.86,4.9A5,5,0,0,1,92,118.53,6.74,6.74,0,0,1,87.8,116.33Z"
    />
    <path
      d="M104.6,118.41l1.05-3.31a6.94,6.94,0,0,0,1.73,1.46,4.85,4.85,0,0,0,1.88.71,2.67,2.67,0,0,0,2.44-1,4.82,4.82,0,0,0,1-3,6.89,6.89,0,0,0-.42-2.65,7.33,7.33,0,0,0-2.43-2.82l-1.46-1.15a7.71,7.71,0,0,1-2.56-3.31,11.24,11.24,0,0,1-.6-4.35,8.26,8.26,0,0,1,1.65-5,4.47,4.47,0,0,1,3.86-1.85c2.06.09,3.47.66,4.24,1.7-.28,1.09-.57,2.18-.86,3.27A5.64,5.64,0,0,0,112.62,96a4.64,4.64,0,0,0-1.92-.61,2.32,2.32,0,0,0-2.08,1,4.8,4.8,0,0,0-.84,2.75,6.62,6.62,0,0,0,.2,1.93,4.2,4.2,0,0,0,.7,1.49,10.91,10.91,0,0,0,1.89,1.78l1.49,1.19a7.78,7.78,0,0,1,2.56,3.42,12.76,12.76,0,0,1,.61,4.82,8,8,0,0,1-1.87,5.16,5,5,0,0,1-4.58,1.69A6.37,6.37,0,0,1,104.6,118.41Z"
    />
    <path
      d="M125.6,96.86q-.12,3.93-.26,7.88l6.16.37c0,1.11-.07,2.21-.11,3.32l-6.16-.4c-.11,3.4-.22,6.81-.34,10.21,2.82.23,5.64.45,8.46.64,0,1.18-.08,2.35-.11,3.52q-5.51-.41-11-.91.48-14.1,1-28.24l11.12.53c0,1.17-.08,2.34-.11,3.52Z"
    />
    <path
      d="M152.57,123.91q-4.5-10.75-9-21.54-.33,10.34-.65,20.67l-2.42-.15q.45-14.39.91-28.78l1,0c2.91,6.78,5.83,13.59,8.76,20.38q.3-10,.61-20l2.41.1q-.43,14.63-.88,29.25Z"
    />
    <path
      d="M172.18,124q.21-6.9.41-13.78c-2.72-.06-5.44-.14-8.15-.22q-.21,6.95-.41,13.88-1.28,0-2.55-.06.42-14.39.85-28.76l2.55.1c-.12,3.84-.23,7.67-.34,11.51,2.71.09,5.43.17,8.14.24.11-3.81.23-7.61.34-11.41l2.54.09q-.42,14.19-.83,28.42Z"
    />
    <path
      d="M193.44,123.68l-1.11-5.77c-2.31,0-4.62.08-6.93.09q-.78,3-1.55,5.93L181,124q4.2-14.34,8.39-28.27l.68,0q3.09,14.15,6.2,27.85Zm-4-20.71q-1.64,6.07-3.25,12.23l5.54,0Q190.53,109.09,189.4,103Z"
    />
    <path
      d="M213.08,122.58,212,117.19q-3.45.18-6.92.33c-.51,1.89-1,3.79-1.54,5.7l-2.84.15q4.19-13.87,8.38-27.1h.67q3.09,13.35,6.18,26.08Zm-4-19.43q-1.62,5.77-3.24,11.67,2.76-.09,5.52-.21Q210.19,108.92,209.05,103.15Z"
    />
    <path
      d="M221.33,120.73c.34-1.08.69-2.16,1-3.23a4.9,4.9,0,0,0,1.73,1,4.24,4.24,0,0,0,1.88.29,3.38,3.38,0,0,0,2.41-1.29,4.46,4.46,0,0,0,1-2.77,4.83,4.83,0,0,0-.42-2.23,5.74,5.74,0,0,0-2.43-2.15l-1.47-.84a5.92,5.92,0,0,1-2.56-2.71,9,9,0,0,1-.62-3.91,7.34,7.34,0,0,1,1.63-4.63,4.72,4.72,0,0,1,3.85-1.77c2,0,3.47.47,4.24,1.33-.28.94-.57,1.89-.85,2.84a6,6,0,0,0-3.43-1.35,2.52,2.52,0,0,0-2.07,1,4.1,4.1,0,0,0-.83,2.51,5.15,5.15,0,0,0,.21,1.71,3.28,3.28,0,0,0,.69,1.28,9.79,9.79,0,0,0,1.9,1.38c.49.3,1,.59,1.49.88a6,6,0,0,1,2.56,2.64,9,9,0,0,1,.62,4,7.65,7.65,0,0,1-1.84,4.75,6.68,6.68,0,0,1-4.56,2.39A5.33,5.33,0,0,1,221.33,120.73Z"
    />
    <path
      d="M69.06,100.73A13.63,13.63,0,0,1,71,93.85a5,5,0,0,1,4.53-2.67,5.3,5.3,0,0,1,4.7,2.91,14.78,14.78,0,0,1,1.39,7.72c-.13,3.34-.78,5.9-2,7.64a4.71,4.71,0,0,1-4.89,2.17,5.62,5.62,0,0,1-4.33-3.45A15.34,15.34,0,0,1,69.06,100.73Zm2.16.19A13.72,13.72,0,0,0,72,106.6c.62,1.57,1.55,2.45,2.79,2.6a3.12,3.12,0,0,0,3.28-1.72,12.51,12.51,0,0,0,1.32-5.85c.2-5.2-1.15-7.88-4-8a3.11,3.11,0,0,0-3,1.88A12.22,12.22,0,0,0,71.22,100.92Z"
    />
    <path class="cls-5" d="M69,113.54q5.8.92,11.62,1.74" />
    <path
      d="M81.88,173h-7V148.24c3-.14,5.17-.22,6.5-.22A9,9,0,0,1,87,149.6a5.41,5.41,0,0,1,2,4.52,4.58,4.58,0,0,1-1.28,3.13A5.42,5.42,0,0,1,84.89,159a7.7,7.7,0,0,1,4.27,2.35,6.79,6.79,0,0,1,1.34,4.45,6.35,6.35,0,0,1-2.38,5.2A9.62,9.62,0,0,1,81.88,173Zm-3.68-22v7.11c.71.06,1.59.1,2.64.1q4.78,0,4.79-3.9,0-3.42-4.41-3.42C80.1,150.83,79.09,150.86,78.2,150.94Zm0,9.72v9.43c1.16.11,2,.17,2.67.17a7.32,7.32,0,0,0,4.63-1.19A4.61,4.61,0,0,0,87,165.28a4.36,4.36,0,0,0-1.39-3.56q-1.39-1.14-4.65-1.14Z"
    />
    <path d="M75.42,209.49V184.77h3.37v24.72Z" />
    <path d="M74.83,246V221.3H78.2V243H89.37v3Z" />
    <text
      class="cls-6"
      transform="matrix(0.44, -0.9, 0.9, 0.44, 158.93, 263.76)"
    >
      SPIER
      <tspan x="5.11" y="29.62">STUK</tspan>
    </text>
    <text class="cls-7" transform="translate(294.24 70.13)">
      R
      <tspan x="0" y="29.62">I</tspan>
      <tspan x="0" y="59.23">B</tspan>
    </text>
    <path d="M326.07,126h9V115.24h3.77V126h11.68v-14.7h4v19.12H322.09V111h4Z" />
    <path
      d="M340,86.69h14.52v4.42H340l-17.91,10.45V97l14.17-8.08-14.17-8.08V76.28Z"
    />
    <path
      d="M326.07,51.89h9V62.6h3.77V51.89h11.68V66.58h4V47.46H322.09V66.83h4Z"
    />
    <path d="M298.38,216.77H296l-12.63-34.36h5.13l8.73,25,8.39-25h5Z" />
    <path
      d="M335.82,215.78l-2.34-7.15H320.87l-2.5,7.15h-5.18L327,181.42h1.22L341,215.78Zm-8.47-25.47-5.25,14.92h10.06Z"
    />
    <path
      d="M304.31,259.33,286.8,234.55v24.32h-4.4V225h1.85l17.06,23.44V225h4.39v34.36Z"
    />
    <path
      d="M341.26,227.57l-1.92,3.81a11.6,11.6,0,0,0-6.85-2.59,10.28,10.28,0,0,0-8.4,3.75q-3.07,3.75-3.08,9.93a14.22,14.22,0,0,0,3,9.46,10.2,10.2,0,0,0,8.23,3.59,8.26,8.26,0,0,0,6-2.23v-7.93h-4.7v-3.94h9.33v14.77a14.12,14.12,0,0,1-5.16,2.53,23.07,23.07,0,0,1-6.44,1q-7,0-11.07-4.75t-4.06-12.65q0-7.86,4.46-12.76t12-4.89A12.56,12.56,0,0,1,341.26,227.57Z"
    />
    <text class="cls-8" transform="translate(393.49 74.5)">DIKKE</text>
    <text class="cls-9" transform="translate(404.69 103.2)">RIB</text>
    <path
      d="M435.73,180.43l1.7-4a8.36,8.36,0,0,0,2.28,1.78,6.87,6.87,0,0,0,2.55.88,4.18,4.18,0,0,0,3.43-1.08,5.41,5.41,0,0,0,1.62-3.5,7,7,0,0,0-.37-3.13,8.83,8.83,0,0,0-3.15-3.45l-1.93-1.44a9,9,0,0,1-3.27-4.08,11.63,11.63,0,0,1-.51-5.25,9.17,9.17,0,0,1,2.66-5.85,6.55,6.55,0,0,1,5.46-1.86q4.24.45,5.72,2.38l-1.44,3.76a7.48,7.48,0,0,0-2-1.46,6.64,6.64,0,0,0-2.59-.88,3.38,3.38,0,0,0-2.94,1,5.27,5.27,0,0,0-1.37,3.21,7,7,0,0,0,.13,2.31,5,5,0,0,0,.85,1.82,15,15,0,0,0,2.48,2.21l2,1.49a9.19,9.19,0,0,1,3.28,4.16,13,13,0,0,1,.48,5.69,9,9,0,0,1-3,6,8,8,0,0,1-6.46,1.92A8.75,8.75,0,0,1,435.73,180.43Z"
    />
    <path
      d="M479.45,154.74l-1.57,3.69q-1.51-1.74-5.11-2.11c-2.25-.24-4.18.81-5.8,3.13a19.85,19.85,0,0,0-3,9.32,17.51,17.51,0,0,0,1.15,9.25q1.73,3.67,5,4a6.14,6.14,0,0,0,5.87-2.67l1.57,3.61a9.61,9.61,0,0,1-8.23,3,8.94,8.94,0,0,1-7.41-5.43q-2.4-4.92-1.62-12.46a22.33,22.33,0,0,1,4.35-11.82q3.57-4.5,8.45-4A10.83,10.83,0,0,1,479.45,154.74Z"
    />
    <path
      d="M501.81,188.84l1.64-15.77-11.17-1.16-1.65,15.76-3.49-.36,3.4-32.62,3.5.36-1.37,13.07,11.18,1.17,1.37-13.07,3.49.36L505.3,189.2Z"
    />
    <path
      d="M462,196l3.5.36-2.34,22.34a7.86,7.86,0,0,0,1,5.06,4.72,4.72,0,0,0,3.73,2.34,4.92,4.92,0,0,0,4.26-1.45,8,8,0,0,0,2-4.92l2.32-22.22,3.49.36-2.37,22.69c-.34,3.3-1.42,5.79-3.22,7.47a8.4,8.4,0,0,1-6.89,2.08,8.27,8.27,0,0,1-6.33-3.41q-2.06-3-1.51-8Z"
    />
    <path
      d="M492.8,232.16l-5.15-.54,3.4-32.62c3.3.19,5.41.34,6.33.43a10.16,10.16,0,0,1,8.06,5q2.69,4.49,2,11.38Q505.57,233.49,492.8,232.16Zm1.33-28.79-2.55,24.38a16.05,16.05,0,0,0,2.88.53,6.5,6.5,0,0,0,6.13-2.91q2.46-3.28,3.11-9.52,1.21-11.58-6.95-12.43C496.43,203.38,495.55,203.37,494.13,203.37Z"
    />
    <path
      d="M448,242.81l-.95,9.06,8.46.89-.4,3.78-8.45-.88-1.23,11.76,11.6,1.21-.42,4-15.09-1.58,3.4-32.62L460.24,240l-.42,4Z"
    />
    <path
      d="M480.81,275.16l-5.37-14.72c-.78-.08-2-.28-3.67-.61l-1.49,14.23-3.49-.36,3.4-32.63c.13,0,1,.06,2.74.12s2.92.14,3.69.22q9.22,1,8.25,10.31a10.52,10.52,0,0,1-2.07,5.38,6.38,6.38,0,0,1-3.84,2.7l5.85,15.78Zm-7.54-29.71-1.11,10.6a14.34,14.34,0,0,0,2.42.47q3.15.33,4.71-.81t1.92-4.46a4.74,4.74,0,0,0-1.13-4.1,7.92,7.92,0,0,0-4.73-1.71A17.66,17.66,0,0,0,473.27,245.45Z"
    />
    <path
      d="M431.89,206.93a19.82,19.82,0,0,1,3.42-9.82q2.79-3.8,6.81-3.38a7.8,7.8,0,0,1,6.55,4.45q2,4,1.33,10.64-.71,6.84-3.54,10.45a7.73,7.73,0,0,1-7.33,3.14c-2.74-.28-4.75-1.87-6-4.76A21.23,21.23,0,0,1,431.89,206.93Zm3.12.33a19.17,19.17,0,0,0,.64,8.12c.77,2.23,2,3.44,3.83,3.63a5.08,5.08,0,0,0,4.95-2.45q1.9-2.76,2.45-8.06,1.13-10.71-5.11-11.36a4.55,4.55,0,0,0-4.5,2.43A17.89,17.89,0,0,0,435,207.26Z"
    />
    <line class="cls-10" x1="430.62" y1="225.48" x2="447.47" y2="227.24" />
    <path
      d="M504.8,298.32c0,1.16,0,2.32,0,3.48l-1.06.25c0-3-.06-6-.1-8.95.8-.31,1.28-.48,1.45-.53,2.26-.7,3.41,0,3.43,1.78a3.51,3.51,0,0,1-3,3.83Zm-.05-4.46c0,1.12,0,2.23,0,3.34l.71-.12a2.25,2.25,0,0,0,2-2.34c0-1.13-.73-1.53-2.13-1.11A4.51,4.51,0,0,0,504.75,293.86Z"
    />
    <path
      d="M513.1,290.78l1.09-.16c0,2.2,0,4.39,0,6.59a2.07,2.07,0,0,0,.47,1.4,1.4,1.4,0,0,0,1.23.46,1.7,1.7,0,0,0,1.26-.65,2.33,2.33,0,0,0,.45-1.54c0-2.21,0-4.41,0-6.62l1.1-.07c0,2.26,0,4.51,0,6.77a3.37,3.37,0,0,1-.74,2.34,2.87,2.87,0,0,1-2,1,2.47,2.47,0,0,1-2.05-.61,3,3,0,0,1-.74-2.21C513.13,295.22,513.12,293,513.1,290.78Z"
    />
    <path
      d="M528.85,300.32c-1.35-2.47-2.71-4.95-4.08-7.39q0,3.49,0,7l-1,0q0-4.87,0-9.76l.44,0c1.34,2.3,2.68,4.65,4,7l0-6.69,1,.13-.06,9.77Z"
    />
    <path
      d="M537.05,293.33,537,301.4c-.36-.08-.71-.15-1.07-.21l.09-8.14c-.83-.21-1.67-.4-2.5-.57,0-.39,0-.77,0-1.16a55.36,55.36,0,0,1,6.15,1.67c0,.37,0,.74,0,1.1C538.77,293.82,537.91,293.56,537.05,293.33Z"
    />
    <path
      d="M497,326h-3V315.39c1.25-.32,2.16-.53,2.72-.63a3.09,3.09,0,0,1,2.39.32,2.26,2.26,0,0,1,.87,2,2.43,2.43,0,0,1-.53,1.53,2.58,2.58,0,0,1-1.19.94,2.88,2.88,0,0,1,1.83.94,3.39,3.39,0,0,1,.59,2.08,3.15,3.15,0,0,1-1,2.48A3.76,3.76,0,0,1,497,326Zm-1.61-9.68c0,1,0,2.08,0,3.13q.45,0,1.11-.09c1.36-.14,2-.81,2-2s-.63-1.5-1.87-1.29A11.31,11.31,0,0,0,495.36,316.29Zm0,4.28c0,1.38,0,2.76,0,4.14a10.55,10.55,0,0,0,1.14.05,3,3,0,0,0,2-.59,2.25,2.25,0,0,0,.61-1.78,2,2,0,0,0-.6-1.62,2.75,2.75,0,0,0-2-.35Z"
    />
    <path
      d="M505.32,319.66a8.7,8.7,0,0,1,1.1-4.61,3.94,3.94,0,0,1,3.1-2.14,3.54,3.54,0,0,1,3.42,1.5,8.44,8.44,0,0,1,1.22,4.86,9.23,9.23,0,0,1-1.14,5.07,3.75,3.75,0,0,1-3.36,1.82,3.59,3.59,0,0,1-3.16-1.84A8.74,8.74,0,0,1,505.32,319.66Zm1.51-.09a8,8,0,0,0,.76,3.58,2.25,2.25,0,0,0,2.05,1.44,2.46,2.46,0,0,0,2.25-1.4,8,8,0,0,0,.75-3.88c0-3.35-1.07-5-3.1-4.82a2.43,2.43,0,0,0-2,1.48A7.9,7.9,0,0,0,506.83,319.57Z"
    />
    <path
      d="M525.32,325.93c-.93-1.9-1.88-3.81-2.82-5.72-.33,0-.84,0-1.55-.09,0,1.94,0,3.88,0,5.81h-1.45l0-13.3,1.14-.08c.71-.05,1.22-.07,1.54-.07,2.59,0,3.87,1.33,3.86,3.86a4.27,4.27,0,0,1-.63,2.24,2.63,2.63,0,0,1-1.47,1.23l3.06,6.12ZM521,314.25v4.33a6,6,0,0,0,1,.09,2.85,2.85,0,0,0,1.91-.52,2.43,2.43,0,0,0,.61-1.87,2,2,0,0,0-.64-1.62,3.36,3.36,0,0,0-2-.5A7.91,7.91,0,0,0,521,314.25Z"
    />
    <path
      d="M531.16,325.34l.54-1.63a3.2,3.2,0,0,0,1,.62,2.87,2.87,0,0,0,1.08.25,1.74,1.74,0,0,0,1.37-.54,2,2,0,0,0,.53-1.4,2.69,2.69,0,0,0-.27-1.19,3.92,3.92,0,0,0-1.43-1.26l-.85-.52a3.78,3.78,0,0,1-1.51-1.52,4.72,4.72,0,0,1-.42-2.07,3.35,3.35,0,0,1,.87-2.37,2.6,2.6,0,0,1,2.18-.78,3.7,3.7,0,0,1,2.44.92c-.14.48-.29,1-.44,1.46a4,4,0,0,0-2-.89,1.33,1.33,0,0,0-1.17.42,1.85,1.85,0,0,0-.44,1.29,2.67,2.67,0,0,0,.15.91,1.84,1.84,0,0,0,.41.69,5.78,5.78,0,0,0,1.11.82l.87.55a3.84,3.84,0,0,1,1.5,1.53,4.65,4.65,0,0,1,.42,2.16,3.29,3.29,0,0,1-1,2.38,3.46,3.46,0,0,1-2.57,1A3.62,3.62,0,0,1,531.16,325.34Z"
    />
    <path
      d="M546.25,316.11c0,3.29,0,6.57-.07,9.86h-1.44c0-3.35,0-6.71.08-10.06-1.11-.17-2.22-.31-3.34-.45,0-.49,0-1,0-1.47,2.76.38,5.5.85,8.2,1.41v1.3C548.55,316.48,547.4,316.29,546.25,316.11Z"
    />
    <path d="M505.92,93.09l-4-5.68V93h-1V85.22h.43l3.91,5.37V85.22h1v7.87Z" />
    <path
      d="M502.59,107.79H501V100l1.91,0a3.4,3.4,0,0,1,2.58,1,3.66,3.66,0,0,1,1,2.63Q506.47,107.79,502.59,107.79Zm-.5-6.81v5.8a5.44,5.44,0,0,0,.88.06,2.07,2.07,0,0,0,1.75-.84,3.76,3.76,0,0,0,.64-2.32c0-1.84-.83-2.75-2.48-2.75C502.78,100.93,502.52,100.94,502.09,101Z"
    />
    <path
      d="M500.92,75.13a5.6,5.6,0,0,1,.66-2.84,2,2,0,0,1,1.8-1.14,2.21,2.21,0,0,1,2,1,5.41,5.41,0,0,1,.67,2.93,5.62,5.62,0,0,1-.68,3,2.15,2.15,0,0,1-1.95,1.09,2,2,0,0,1-1.81-1.15A5.89,5.89,0,0,1,500.92,75.13Zm.87,0a5.3,5.3,0,0,0,.42,2.24,1.3,1.3,0,0,0,1.17.9,1.43,1.43,0,0,0,1.31-.83,4.88,4.88,0,0,0,.45-2.31c0-2-.59-3-1.76-3a1.27,1.27,0,0,0-1.18.81A5,5,0,0,0,501.79,75.13Z"
    />
    <line class="cls-11" x1="501.1" y1="80.33" x2="505.85" y2="80.33" />
    <path d="M518.73,87.83V90h2.56v.9h-2.56v2.8h3.52v1h-4.58V86.88h4.63v1Z" />
    <path
      d="M521.72,109.45l-2.07-3.33c-.24,0-.61,0-1.12-.05v3.38h-1.06v-7.76l.82,0c.51,0,.88,0,1.12,0,1.86,0,2.8.74,2.8,2.22a2.11,2.11,0,0,1-.45,1.32,1.85,1.85,0,0,1-1.08.73l2.26,3.57Zm-3.19-6.81v2.53a6.27,6.27,0,0,0,.74,0,2.4,2.4,0,0,0,1.39-.31,1.25,1.25,0,0,0,.43-1.1,1,1,0,0,0-.46-.94,3,3,0,0,0-1.48-.28Z"
    />
    <path
      d="M562.92,224.49V212.55h-10.8v11.94h-3.38V199.78h3.38v9.9h10.8v-9.9h3.37v24.71Z"
    />
    <path
      d="M589.17,224.49l-1.7-5.21h-9.2l-1.82,5.21h-3.78l10-25.05h.89l9.32,25.05ZM583,205.92l-3.83,10.88h7.33Z"
    />
    <path d="M548.74,260V235.32h3.38V257h11.17v3Z" />
    <path
      d="M572.74,258.9l1.23-3.1a8.44,8.44,0,0,0,2.35,1.15,8.18,8.18,0,0,0,2.53.47,4.56,4.56,0,0,0,3.17-1.08,3.53,3.53,0,0,0,1.19-2.75,4.34,4.34,0,0,0-.66-2.32,8.08,8.08,0,0,0-3.35-2.34l-2-.93a7.91,7.91,0,0,1-3.54-2.8,7.24,7.24,0,0,1-1-3.89,6,6,0,0,1,2-4.59,7.05,7.05,0,0,1,5-1.83q4.11,0,5.71,1.34l-1,2.93a7.74,7.74,0,0,0-2.06-.94,8,8,0,0,0-2.56-.46,3.69,3.69,0,0,0-2.71,1,3.38,3.38,0,0,0-1,2.51,4.09,4.09,0,0,0,.36,1.73,3.51,3.51,0,0,0,1,1.29,14.34,14.34,0,0,0,2.59,1.47l2,1a8.08,8.08,0,0,1,3.55,2.86,8,8,0,0,1,1,4.23,6,6,0,0,1-2.23,4.72,8.78,8.78,0,0,1-6,1.94A9.84,9.84,0,0,1,572.74,258.9Z"
    />
    <path
      d="M604,326.09l23.13-16.7-11.43-3.88-14.91,2.67-1-5.89,55-9.86,1,5.89-29.93,5.37,32.8,10.66,1.18,6.57-26.15-8.63L605.17,332.6Z"
    />
    <path
      d="M639.05,399.68l-21.18,3.79-1-5.89,55-9.86c1,4.42,1.65,7.08,1.82,8Q677,414.53,661,417.39q-18.54,3.33-21.53-13.29C639.34,403.44,639.2,402,639.05,399.68Zm27.07-4.85-20.31,3.64c.19,2.53.31,3.87.33,4q2,11,13,9,10-1.82,8-13.5A18.18,18.18,0,0,0,666.12,394.83Z"
    />
    <path
      d="M640,342.51q9.63-1.72,16.8.9t8.31,9.07a12.66,12.66,0,0,1-4,12.18q-5.28,4.93-15.94,6.84-10.93,2-17.82-.8a12.55,12.55,0,0,1-8.19-10q-1.17-6.58,4.7-11.47T640,342.51Zm.9,5a30.81,30.81,0,0,0-12.33,4.65q-4.7,3.28-3.92,7.58.87,4.88,6,6.59t13.64.19q17.13-3.08,15.35-13.05c-.53-2.95-2.46-4.91-5.81-5.9S646.22,346.55,640.93,347.5Z"
    />
    <line class="cls-12" x1="610.64" y1="348.87" x2="615.47" y2="375.84" />
    <text
      class="cls-13"
      transform="translate(397.56 354.37) rotate(8.02) scale(0.91 1.16)"
    >
      SCHENKE
      <tspan class="cls-14" x="71.5" y="0">L</tspan>
    </text>
    <text
      class="cls-15"
      transform="translate(72.05 362.26) rotate(-69.42) scale(0.91 1.16)"
    >
      SCHENKE
      <tspan class="cls-16" x="87.64" y="0">L</tspan>
    </text>
    <text
      class="cls-17"
      transform="translate(148.87 302.67) rotate(80.42) scale(0.91 1.16)"
    >
      SCHENKE
      <tspan class="cls-18" x="66.39" y="0">L</tspan>
    </text>
    <path
      d="M395.21,259.17l-8.57,2.49,2.75,1.93,4.59.5-.42,1.62-16.94-1.86.51-1.62,9.14,1-7.78-5.41L379,256l6.13,4.35,10.49-3Z"
    />
    <path d="M396.79,250.88l-16.27-1.13.35-1.63,14.17,1,.84-5.4,1.95.14Z" />
    <path
      d="M399.1,230.76l-3.28.69-.42,4.46,3.2,1-.15,1.83-15.17-5.5.06-.43,15.9-3.89Zm-11.78,2.53,6.57,2.13.35-3.56Z"
    />
    <path
      d="M393.9,221.13l5.65.06-.05,1.64-14.75-.16c0-1.24.08-2,.1-2.25q.47-5.22,4.63-5.18,4.81.06,4.58,4.68C394.05,220.11,394,220.51,393.9,221.13Zm-7.21-.08,5.41.06c.11-.69.17-1.06.17-1.1.12-2-.79-3-2.71-3.08-1.75,0-2.72,1.06-2.89,3.23A5.6,5.6,0,0,0,386.69,221.05Z"
    />
    <path
      d="M400.22,201.94l-6.05,3q0,.56-.24,1.74l6,.2-.1,1.64L386,208c0-.06,0-.49.08-1.28s.09-1.36.13-1.72c.33-2.89,1.78-4.28,4.34-4.19a3.84,3.84,0,0,1,2.22.77,2.76,2.76,0,0,1,1.12,1.7l6.44-3.26Zm-12.35,4.52,4.47.15a8,8,0,0,0,.19-1.14,3.36,3.36,0,0,0-.34-2.16,2.22,2.22,0,0,0-1.85-.74,2,2,0,0,0-1.71.66,4.25,4.25,0,0,0-.75,2.26A8.85,8.85,0,0,0,387.87,206.46Z"
    />
    <path d="M401.17,193.81l-12.89-1.23.29-1.63,12.79,1.23Z" />
    <path
      d="M402.57,181.12l-.37,3.4-12.33-1.24c.17-1.46.31-2.51.41-3.15a4.69,4.69,0,0,1,1.22-2.66,2.75,2.75,0,0,1,2.32-.74,2.13,2.13,0,0,1,1.43.78,2.49,2.49,0,0,1,.66,1.45,3.84,3.84,0,0,1,1.42-1.94,3.36,3.36,0,0,1,2.23-.43A3.08,3.08,0,0,1,402,178,4.67,4.67,0,0,1,402.57,181.12Zm-11.1.66,3.51.36c.08-.34.16-.76.23-1.26.22-1.55-.3-2.38-1.57-2.52-1.1-.11-1.77.54-2,2C391.56,180.86,391.5,181.35,391.47,181.78Zm4.8.49,4.68.48a12.92,12.92,0,0,0,.23-1.28,3.44,3.44,0,0,0-.33-2.3,2.7,2.7,0,0,0-3.58-.4,3.67,3.67,0,0,0-.86,2.19Z"
    />
    <path
      d="M541.76,120.78l-4-6.49c-.46,0-1.19,0-2.18-.1v6.59h-2.07V105.66l1.61-.07c1-.06,1.71-.08,2.17-.08q5.44,0,5.45,4.33a4.15,4.15,0,0,1-.88,2.57,3.69,3.69,0,0,1-2.08,1.42l4.38,7Zm-6.22-13.26v4.91a10.19,10.19,0,0,0,1.44.11,4.73,4.73,0,0,0,2.7-.6,2.43,2.43,0,0,0,.85-2.14,2,2,0,0,0-.91-1.82,5.56,5.56,0,0,0-2.87-.56C536.55,107.42,536.14,107.45,535.54,107.52Z"
    />
    <path d="M550.31,138.55V123.43h2.07v15.12Z" />
    <path
      d="M562.45,158.3h-4.31V143.18c1.84-.09,3.16-.14,4-.14a5.45,5.45,0,0,1,3.47,1,3.29,3.29,0,0,1,1.23,2.76,2.78,2.78,0,0,1-.79,1.92,3.29,3.29,0,0,1-1.73,1.08,4.69,4.69,0,0,1,2.62,1.44,4.18,4.18,0,0,1,.82,2.72,3.89,3.89,0,0,1-1.46,3.18A5.89,5.89,0,0,1,562.45,158.3Zm-2.25-13.47v4.34c.44,0,1,.06,1.61.06,2,0,2.93-.79,2.93-2.38,0-1.4-.89-2.09-2.69-2.09C561.36,144.76,560.75,144.78,560.2,144.83Zm0,5.94v5.77c.71.07,1.25.1,1.63.1a4.52,4.52,0,0,0,2.84-.72,2.84,2.84,0,0,0,.89-2.32,2.67,2.67,0,0,0-.85-2.18,4.58,4.58,0,0,0-2.85-.7Z"
    />
  </svg>
</div>

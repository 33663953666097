import { Component, Input, Output, EventEmitter, OnInit } from "@angular/core";
import { TypeaheadMatch } from "ngx-bootstrap/typeahead";

@Component({
  selector: "goenga-typeahead",
  templateUrl: "./goenga-typeahead.component.html",
  styleUrls: ["goenga-typeahead.component.css"],
})
export class GoengaTypeaheadComponent implements OnInit {
  public displayLabel: string;
  public _selectedValue: any;

  @Input() values: any;
  @Input() displayValue: string;
  @Input() placeholder: string = "Zoek op naam, nummer of omschrijving";
  @Input() searchFields: any;
  @Input() icon: any;
  @Input() customItemTemplate: any;
  @Input() optionField: string;

  get selectedValue(): any {
    return this._selectedValue;
  }

  @Input()
  set selectedValue(selectedValue: any) {
    if (selectedValue == null) {
      this.displayLabel = null;
    } else {
      this.displayLabel = selectedValue[this.displayValue.toString()];
    }
    this._selectedValue = selectedValue;
  }

  @Output() selectedValueChange = new EventEmitter();

  constructor() {}

  ngOnInit(): void {
    if (this.selectedValue) {
      this.displayLabel = this.selectedValue[this.displayValue.toString()];
    }
  }

  changeValue(match: TypeaheadMatch) {
    this.displayLabel = match.item[this.displayValue.toString()];
    this.selectedValue = match.item;
    this.selectedValueChange.emit(this.selectedValue);
  }

  clear() {
    this.selectedValue = null;
    this.displayLabel = null;
    this.selectedValueChange.emit(this.selectedValue);
  }
}

<div class="goenga-dropdown shopping-cart" [ngClass]="{'show': show}">
  <div class="title">
    Je winkelmandje
  </div>
  <table class="table mb-1">
    <tr *ngFor="let item of items">
      <td class="items">{{ item.omschrijving }}</td>
      <td class="items">{{ item.aantal }}</td>
      <td class="items">{{ item.eenheid.naam }}</td>
    </tr>
    <tr *ngIf="items?.length == 0">
      <td>
        <i class="items">Winkelmandje is leeg</i>
      </td>
    </tr>
  </table>
  <button class="button gold block m-auto" (click)="navigate()">
    Naar winkelmandje
  </button>
</div>
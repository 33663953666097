import {
  Directive,
  ElementRef,
  Input,
  HostListener,
  Renderer2,
  HostBinding,
} from "@angular/core";

@Directive({
  selector: "[goengaDropDown]",
})
export class DropDownDirective {
  constructor(private element: ElementRef, private renderer: Renderer2) {}

  @HostBinding("class.show") isOpen = false;

  @HostListener("mouseenter") open() {
    this.isOpen = true;
    let part = this.element.nativeElement.querySelector(".goenga-dropdown");
    if (this.isOpen) this.renderer.addClass(part, "show");
    else this.renderer.removeClass(part, "show");
  }

  @HostListener("mouseleave") close() {
    this.isOpen = false;
    let part = this.element.nativeElement.querySelector(".goenga-dropdown");
    if (this.isOpen) this.renderer.addClass(part, "show");
    else this.renderer.removeClass(part, "show");
  }
}

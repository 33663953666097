<app-header></app-header>

<div class="identity">
  <div class="title">Echt Texels lamsvlees komt van Slagerij Goënga</div>
  <div class="text">
    Topkoks en culifanaten weten het allang: goed lamsvlees komt van Texel. Van
    Slagerij Goënga om precies te zijn. Van lammeren die zijn geboren en
    opgegroeid op de landerijen van Waddeneiland Texel. Na een goed leven in
    vrijheid worden ze professioneel geslacht en met liefde verwerkt tot goed,
    verantwoord vlees. De slagers van Goënga weten hoe dat moet. Dat doen ze al
    sinds 1935.
  </div>
  <!-- <div class="icon">
        <img src="./assets/images/icon.png">
    </div> -->
</div>

<div class="order-categories">
  <div class="main-title">Texels vlees bestellen</div>
  <div class="categories">
    <div class="button" (click)="navigateToOrder(1)">
      <div class="icon">
        <i class="goenga-icon icon-lam"></i>
      </div>
      <div class="main-title">Lamsvlees</div>
      <div class="sub-title">Onze specialiteit!</div>
    </div>

    <div class="button" (click)="navigateToOrder(2)">
      <div class="icon">
        <i class="goenga-icon icon-koe"></i>
      </div>
      <div class="main-title">Rundvlees</div>
    </div>

    <div class="button" (click)="navigateToOrder(3)">
      <div class="icon">
        <i class="goenga-icon icon-varken"></i>
      </div>
      <div class="main-title">Varkensvlees</div>
    </div>

    <div class="button" (click)="navigateToOrder(4)">
      <div class="icon">
        <i class="goenga-icon icon-bbq"></i>
      </div>
      <div class="main-title">BBQ</div>
    </div>

    <div class="button" (click)="navigateToOrder(6)">
      <div class="icon">
        <i class="goenga-icon icon-ham"></i>
      </div>
      <div class="main-title">Vleeswaren</div>
    </div>
  </div>
</div>

<div class="bestsellers">
  <div class="button">Of bekijk onze bestsellers</div>
</div>

<div class="recommendations">
  <div class="row-container">
    <div class="block-row mb-3">
      <div class="block-full">
        <div class="tips">
          <div class="tip-rectangle">
            <div class="header">Bereidingstips</div>
            <div class="sub-title">
              Hoe maak je een lekker gerecht met ons vlees?
            </div>
            <button
              class="button button-white"
              routerLink="/heerlijke-recpeten"
            >
              Bekijk de tips van Minne
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="block-row">
      <div class="block-wide">
        <div class="block-full">
          <div class="trace">
            <div class="header">Waar komt ons vlees vandaan?</div>
            <div class="sub-title">
              Je bekijkt het met onze unieke traceerbare producten
            </div>
            <button
              class="button button-white"
              routerLink="/herkomst-van-ons-vlees"
            >
              Lees meer
            </button>
          </div>
        </div>
        <div class="block-full">
          <div class="spar">
            <div class="header">Zoek je andere Texelse producten?</div>
            <div class="sub-title">
              Op Texelsbestellen.nl vind je nog veel meer heerlijke Texelse
              producten. Snel en vers bij je thuisbezorgd.
            </div>
            <button class="button button-white">
              Ga naar texelsbestellen.nl
            </button>
          </div>
        </div>
      </div>
      <div class="block-small">
        <div class="history">
          <div class="title">
            Al meer dan 85 jaar Texelse slagers met een missie.
          </div>
          <button
            class="button button-white"
            routerLink="/over-slagerij-goenga"
          >
            Maak kennis met de familie Goënga
          </button>
        </div>
      </div>
    </div>
  </div>
  <div class="title">
    <div class="main-title">Echt Texels vlees</div>
    <div class="sub-title">
      Hierom kies je voor echt Texels lamsvlees van Goënga
    </div>
  </div>
  <div class="points">
    <ul>
      <li>
        Eerlijk vlees van lammeren die op Texel zijn geboren en opgegroeid
      </li>
      <li>Van elk stukje lamsvlees is de Texelse herkomst bekend</li>
      <li>Altijd vers, het hele jaar door</li>
      <li>Vers en gekoeld verpakt in duurzame ecoboxen</li>
      <li>Snelle levering door heel Nederland</li>
    </ul>
  </div>
</div>

<div class="title">
  <h1>{{ title }}</h1>
</div>

<div class="page">
  <div class="picture">
    <img [lazyLoad]="image" defaultImage="/assets/images/no-img-available.png" />
  </div>
  <div class="content {{ size }}">
    <ng-content></ng-content>
  </div>
</div>
export const environment = {
  production: true,
  environment: "tst",
  apiUrl: 'https://api-tst.slagerijgoenga.nl',
  paymentUrl: "https://betalen-tst.slagerijgoenga.nl",
  postcodeApiKey: "6b5c471d-70ef-43a3-abfb-eecbb62284c1",
  siteAddress: "http://tst.slagerijgoenga.nl",
  tenantId: "bac97e24-6b0f-42ec-8fd8-849147eee0ed",
  applicationId: "6fbf6fc0-3597-47de-9898-b4ac4499c89a",
  //ga: "UA-114910981-1"
};
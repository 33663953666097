import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { DefaultPageComponent } from "./components/default-page/default-page.component";
import { MenuCloseDirective } from "./directives/menu-close.directive";
import { ShoppingCartModalComponent } from "./components/order-shoppingcart-modal/shopping-cart-modal.component";
import { DropDownDirective } from "./directives/dropdown.directive";
import { LoaderComponent } from "./components/loader/loader.component";
import { SearcherComponent } from "./components/searcher/searcher.component";
import { TypeaheadModule } from "ngx-bootstrap/typeahead";
import { FormsModule } from "@angular/forms";
import { GoengaTypeaheadComponent } from "./components/goenga-typeahead/goenga-typeahead.component";
import { CustomTypeaheadDirective } from "./directives/custom-typeahead.directive";
import { LambSpecifyImageComponent } from "./components/lamb-specify-image/lamb-specify-image.component";
import { BeefSpecifyImageComponent } from "./components/beef-specify-image/beef-specify-image.component";
import { FocusedDirective } from "./directives/focused.directive";
import { PigSpecifyImageComponent } from "./components/pig-specify-image/pig-specify-image.component";
import { MapsPageComponent } from "./components/maps-page/maps-page.component";
import { GoogleMapsModule } from "@angular/google-maps";
import { DocumentPreviewModalComponent } from "./components/document-preview-modal/document-preview-modal.component";
import { BsModalService, ModalModule } from "ngx-bootstrap/modal";
import { TimeStringPipe } from "./pipes/time.pipe";
import { LazyLoadImageModule } from "ng-lazyload-image";
import { AzureImageComponent } from "./components/azure-image/azure-image.component";

@NgModule({
  declarations: [
    DefaultPageComponent,
    MenuCloseDirective,
    ShoppingCartModalComponent,
    DropDownDirective,
    LoaderComponent,
    SearcherComponent,
    GoengaTypeaheadComponent,
    CustomTypeaheadDirective,
    LambSpecifyImageComponent,
    BeefSpecifyImageComponent,
    FocusedDirective,
    PigSpecifyImageComponent,
    MapsPageComponent,
    DocumentPreviewModalComponent,
    TimeStringPipe,
    AzureImageComponent
  ],
  imports: [
    CommonModule,
    TypeaheadModule,
    ModalModule,
    FormsModule,
    GoogleMapsModule,
    LazyLoadImageModule,
  ],
  exports: [
    DefaultPageComponent,
    MenuCloseDirective,
    ShoppingCartModalComponent,
    DropDownDirective,
    LoaderComponent,
    SearcherComponent,
    GoengaTypeaheadComponent,
    CustomTypeaheadDirective,
    LambSpecifyImageComponent,
    BeefSpecifyImageComponent,
    FocusedDirective,
    PigSpecifyImageComponent,
    MapsPageComponent,
    TimeStringPipe,
    AzureImageComponent
  ],
  entryComponents: [DocumentPreviewModalComponent, GoengaTypeaheadComponent],
})
export class SharedModule { }

import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { BehaviorSubject, Observable, of } from "rxjs";
import { map } from "rxjs/operators";
import { ApiAuth, ApiKlant, ApiUser, LoginRequest, RefreshTokenRequest, RefreshTokenResponse, RequestNewPasswordRequest, UserModel } from "../api-service";
import { environment } from "../../environments/environment";
import { SessionStorageService } from "./session-storage.service";
import { CurrentUser, Customer } from "../authentication/customer";
import { ToastrService } from "ngx-toastr";

@Injectable({ providedIn: "root" })
export class AuthenticationService {
  private currentUserSubject: BehaviorSubject<CurrentUser>;
  public currentUser: Observable<CurrentUser>;

  constructor(
    private apiAuth: ApiAuth,
    private apiklant: ApiKlant,
    private router: Router,
    private sessionStorageService: SessionStorageService,
    private apiUser: ApiUser,
    private toastrService: ToastrService
  ) {
    this.currentUserSubject = new BehaviorSubject<CurrentUser>(
      JSON.parse(localStorage.getItem("currentUser"))
    );
    this.currentUser = this.currentUserSubject.asObservable();
  }

  public get currentUserValue(): CurrentUser {
    return this.currentUserSubject.value;
  }

  public login(username: string, password: string): Observable<CurrentUser> {
    return new Observable<CurrentUser>((observer: any) => {
      var request = new LoginRequest();
      request.username = username;
      request.password = password;
      request.application = "Order";
      this.apiAuth.login(request).subscribe(loginResult => {
        localStorage.setItem("accessToken", loginResult.token);
        localStorage.setItem('refreshToken', loginResult.refreshToken);
        localStorage.setItem("privateFeatureFlags", JSON.stringify(loginResult.featureFlags));
        localStorage.setItem("customerId", loginResult.user.customerId.toString());
        this.sessionStorageService.personalData = null;
        this.sessionStorageService.processStatus = 1;
        // Map user and fill customer object 
        this.apiklant.get(loginResult.user.customerId)
          .subscribe(customer => {
            var currentUser = new CurrentUser();
            currentUser.email = loginResult.user.email;
            currentUser.id = loginResult.user.id;
            //currentUser.imageUrl = loginResult.user.imageUrl;
            currentUser.application = loginResult.user.application.toString();
            currentUser.username = loginResult.user.userName;
            currentUser.fullName = loginResult.user.fullName;
            currentUser.customerId = loginResult.user.customerId;
            currentUser.customer = new Customer();
            currentUser.customer.id = customer.id;
            currentUser.customer.firstName = customer.voornaam;
            currentUser.customer.middleName = customer.tussenvoegsel;
            currentUser.customer.lastName = customer.achternaam;
            currentUser.customer.isParticulier = customer.isParticulier;
            currentUser.customer.companyName = customer.bedrijfsnaam;
            localStorage.setItem("currentUser", JSON.stringify(currentUser));
            this.currentUserSubject.next(currentUser);
            observer.next(currentUser);
            observer.complete();
          }, ((err) => {
            observer.error();
          }));

      }, ((err) => {
        observer.error();
      }));
    });
  }

  public getToken() {
    return localStorage.getItem("accessToken");
  }


  public getRefreshToken() {
    return localStorage.getItem('refreshToken');
  }


  public getCustomerId(): number | undefined {
    var customerId = localStorage.getItem("customerId");
    return customerId === null ? undefined : Number(customerId);
  }

  public renewToken(): Observable<RefreshTokenResponse> {
    var refreshRequest = new RefreshTokenRequest()
    refreshRequest.expiredToken = this.getToken();
    refreshRequest.refreshToken = this.getRefreshToken();
    return this.apiAuth.refreshToken(refreshRequest).pipe(map(
      (result => {
        localStorage.setItem('accessToken', result.token);
        localStorage.setItem('refreshToken', result.refreshToken);
        return result;
      })));
  }

  public isUserLoggedIn() { }

  sendForgotPasswordLink(email) {
    const forgotPasswordRequest = new RequestNewPasswordRequest({
      returnUrl: `${environment.siteAddress}/authenticatie/wachtwoord-instellen`,
      emailAddress: email
    });

    return this.apiUser.requestNewPassword(forgotPasswordRequest);
  }

  public logout() {
    localStorage.removeItem("currentUser");
    localStorage.removeItem("accessToken");
    localStorage.removeItem("refreshToken");
    localStorage.removeItem("privateFeatureFlags");
    localStorage.removeItem("customerId");
    localStorage.removeItem("isParticulier");
    this.sessionStorageService.personalData = null;
    this.sessionStorageService.processStatus = 1;
    this.currentUserSubject.next(null);
    this.router.navigate(["/authenticatie/inloggen"]);
    this.currentUserSubject.next(null);
  }
}

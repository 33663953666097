<goenga-default-page
  image="/assets/images/pages/page-texel.jpg"
  title="Texel, meer dan schapeneiland"
>
  <h3>Welkom op het grootste Waddeneiland van Nederland</h3>
  <p>
    Misschien heb je weleens vakantie op Texel gevierd of ben je hier inmiddels
    vaste gast. Dan weet je ongetwijfeld al dat Texel een superplek is! Ben jij
    nog nooit op het eiland geweest? Dan nemen we je in vogelvlucht mee over het
    eiland.
  </p>

  <h3>Natuurlijk Texel</h3>
  <p>
    Een groot deel van de bezoekers komt naar Texel voor de rust en ruimte. Het
    eiland heeft fantastische natuurgebieden. Maar liefst zo’n 40! De hele
    westkust, van het zuidpuntje op De Hors tot aan de vuurtoren in het noorden,
    is zelfs ondergebracht in Nationaal Park Duinen van Texel. En de Waddenzee,
    waar Texel aan grenst, is Unesco Werelderfgoed. Ook aan de rustigere
    oostkant en centraal op het eiland kun je heerlijk fietsen en wandelen in
    prachtige natuur. Over het hele eiland komen bijzondere plantensoorten en
    vogels voor.
  </p>

  <h3>Vogeleiland</h3>
  <p>
    Behalve schapeneiland is Texel ook vogeleiland bij uitstek. Op het eiland
    komen zo’n 400 verschillende soorten voor! Een paradijs voor vogelaars dus.
    Ieder jaar weer worden hier soorten gespot die elders in Nederland niet of
    nauwelijks voorkomen. Meer weten over vogels? Vogelinformatiecentrum Texel
    informeert je graag en organiseert vogelexcursies.
  </p>

  <h3>Cultuurlandschap</h3>
  <p>
    De Hoge Berg is een bijzonder stukje Texel tussen Den Burg en Oudeschild. Je
    vindt er de typisch Texelse schapenboeten en tuunwallen. En natuurlijk het
    hele jaar door veel lammetjes en schapen! Dit is het oudste deel van Texel
    en uitgeroepen tot Nederlands icoonlandschap. De schapenboeren in het gebied
    doen er veel aan om dit bijzondere, cultuurhistorische landschap te
    behouden. Want schapen zijn een onmisbaar element in het landschap van de
    Hoge Berg.
  </p>

  <h3>Outdoor-activiteiten</h3>
  <p>
    Op Texel wil je buiten zijn! Dat kan ook actief. Je kunt hier golfsurfen,
    kitesurfen, branding kanoën, fietsen, hardlopen, skaten, parachutespringen,
    hiken, vliegeren en nog veel meer. Er zijn verschillende bedrijven met
    uitdagende outdoor programma’s, zoals Texel Active. Maar ook wandelen over
    de Hors is een echte aanrader. Deze uitgestrekte zandvlakte is een
    prachtige, onontgonnen wildernis op Texel.
  </p>

  <h3>Texel in cijfers</h3>
  <p>
    Om te beginnen: Texel is de grootste stad van Nederland. Niet in
    inwonersaantal, maar wel in oppervlakte! En dat komt vooral door de
    buitenwateren rondom het eiland. Die zijn ook Texels grondgebied. De totale
    oppervlakte van Texel is ruim 585 km2.
  </p>
  <ul>
    <li>Texel heeft sinds 1415 stadsrechten</li>
    <li>
      Er wonen bijna 14.000 mensen en net zoveel schapen (plus in het voorjaar
      zo’n 22.000 lammetjes)
    </li>
    <li>Er ligt zo’n 140 kilometer fietspad</li>
    <li>
      Er is maar één kruising op het hele eiland met verkeerslichten. En dat is
      bij de Tesoboot
    </li>
    <li>
      Jan Wolkers (1923-1993) was ereburger van Texel. Veel bekende Nederlanders
      komen vaak en graag op Texel
    </li>
    <li>
      Op Texel regent het minder, waait het meer en schijnt de zon langer dan in
      de rest van Nederland
    </li>
    <li>
      Oudere Texelaars Texels dialect spreken. Deer bè je mooi onkléèd (daar ben
      je mooi klaar mee)
    </li>
  </ul>
  Meer weten over Texel? Kijk op <a href="https://texel.net">Texel.net</a>
</goenga-default-page>

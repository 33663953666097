import { Component, Input, OnInit } from "@angular/core";

@Component({
  selector: "goenga-default-page",
  templateUrl: "./default-page.component.html",
  styleUrls: ["./default-page.component.scss"],
})
export class DefaultPageComponent implements OnInit {
  @Input() public title: string;
  @Input() public image: string;
  @Input() public size: string = "middle";

  constructor() {}

  ngOnInit() {}
}

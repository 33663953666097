import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { SessionStorageService } from "src/app/services/session-storage.service";

@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.scss"],
})
export class HeaderComponent implements OnInit {

  constructor(
    private router: Router,
    private sessionStorageService: SessionStorageService,
  ) { }

  ngOnInit() { }

  navigateToOrder(category: number) {
    this.sessionStorageService.category = category;

    this.router.navigate(["/bestellen"]);
  }
}

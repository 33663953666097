import { Component, OnInit } from "@angular/core";
import { Meta, Title } from "@angular/platform-browser";

@Component({
  selector: "goenga-recipes",
  templateUrl: "./recipes.component.html",
  styleUrls: ["./recipes.component.scss"],
})
export class RecipesComponent implements OnInit {
  constructor(
    title: Title,
    meta: Meta,) {
    title.setTitle("Hoe bereid je lamsvlees | Echt Texels lamsvlees komt van Slagerij Goënga");
    meta.addTags([
      { name: 'description', content: 'Bereidings tips voor Texels lamsvlees van Slagerij Goënga' },
      { name: 'keywords', content: 'texel, schapeneiland, landschap, lammetjes, lente, activteiten, supermarkt, lamsvlees, lamsrack, lamsbout,lamskotelet, lamasschouder, lamskarobnade, lamsgehakt, lamsmuis, lamshaas, lamsfilet, lamsrug, lamsham, lamsworst,waddengoud, echt texels, Echt Texels Lamsvlees, lamsvlees bestellen, bestellen, on-line, lam, bezorging door heel Nederland, vers, altijd in voorraad, binnen 24 uur bezorgd,slagerij goenga, Den Hoorn, Texel, kaas, brandnetel, mosterd, peper, boerenkaas, kaas, schapenkaas, wild, echt texels' },
    ]);
  }
  ngOnInit() {}
}

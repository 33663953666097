<div class="title">
  Dit product is afkomstig uit de <strong>{{ part }}</strong> van het lam
</div>
<div class="img">
  <svg
    id="Laag_1"
    data-name="Laag 1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 738 542"
  >
    <path
      class="cls-1"
      d="M188.73,424c21-2.59,38-16.35,43.4-34.4l3.67,1.09c5.87,34.19,38.69,60.37,78.31,60.37,32,0,59.61-17.12,72.1-41.75a44,44,0,0,0,28,12.16"
    />
    <path
      class="cls-1"
      d="M506,376.19c40.92-6.07,72.14-38.06,72.14-76.63a70.92,70.92,0,0,0-7.42-31.5c19.72-15.63,32.18-38.51,32.18-64"
    />
    <path
      class="cls-1"
      d="M146.51,412.35c-10.68-8.64-17.4-21.17-17.4-35.12a42.74,42.74,0,0,1,1.53-11.3c-41.79-5.26-74-37.55-74-76.62a70.5,70.5,0,0,1,7.08-30.76c-16.72-8.61-28-24.82-28-43.4,0-17.89,10.45-33.57,26.14-42.4a33.11,33.11,0,0,1,2-6.6,27.73,27.73,0,0,1-8-5.27,29.18,29.18,0,0,1-20.19,7.85c-15.33,0-27.76-11.19-27.76-25s12.43-25,27.76-25A29.18,29.18,0,0,1,56,126.61a29.95,29.95,0,0,1,39.3-1C105.88,96.11,136.65,74.76,173,74.76a90.75,90.75,0,0,1,18.6,1.94c17.8-35.35,57.33-59.94,103.24-59.94A123.1,123.1,0,0,1,345.93,27.7a106.71,106.71,0,0,1,47.42-10.94c35.28,0,66.21,16.7,83.62,41.79A72.27,72.27,0,0,1,553.38,55a43.84,43.84,0,0,1,55.07,1,29.32,29.32,0,0,1,22.64-10.23c15.59,0,28.22,11.38,28.22,25.41"
    />
    <path class="cls-1" d="M643.68,93.06c12-4,16-16,15.64-21.89" />
    <line class="cls-1" x1="188.73" y1="423.99" x2="188.73" y2="423.98" />
    <line class="cls-1" x1="146.51" y1="412.35" x2="146.51" y2="412.34" />
    <line class="cls-1" x1="414.23" y1="421.46" x2="414.23" y2="421.45" />
    <polyline
      class="cls-1"
      points="456.43 397.58 456.43 397.55 456.45 397.48"
    />
    <path
      class="cls-1"
      d="M602.92,204c117.45,31.2,129.14,2.74,124.18-19.26a36.09,36.09,0,0,0-17-23c-5.73-3.42-14-10.64-23.5-25.95-17.92-29-42.68-43-42.68-43"
    />
    <path
      class="cls-1"
      [ngClass]="{ selected: part == 'poot' }"
      d="M479.27,491.33s9.64,57.69-21.35,20.91c-3.4-4-6.24-7.43-8.62-10.31a178.14,178.14,0,0,1,3.51,19.68c-14.41,15.87-29.64,0-29.64,0L414.23,492V421.46c1,.06,2,.09,3,.09,17.8,0,33-9.93,39.21-24h0a34.59,34.59,0,0,0,.38-27.06,94.1,94.1,0,0,0,35.07,6.71,95.23,95.23,0,0,0,14.13-1Z"
    />
    <path
      class="cls-1"
      [ngClass]="{ selected: part == 'poot' }"
      d="M147,413.32l-.83,78.24,9,30s18,16,30,0c-2-12-3-19-3-19s38,56,29-11c7-27,22-96,22-96S200.84,447.08,147,413.32Z"
    />
    <path
      class="cls-1"
      d="M479.27,491.33s9.64,57.69-21.35,20.91c-3.4-4-6.24-7.43-8.62-10.31a178.14,178.14,0,0,1,3.51,19.68c-14.41,15.87-29.64,0-29.64,0L414.23,492V421.46c1,.06,2,.09,3,.09,17.8,0,33-9.93,39.21-24h0a34.59,34.59,0,0,0,.38-27.06,94.1,94.1,0,0,0,35.07,6.71,95.23,95.23,0,0,0,14.13-1Z"
    />
    <path
      class="cls-2"
      [ngClass]="{ selected: part == 'bout' }"
      d="M180.22,76.14s76.46,126.92,31.72,337.48c-12.26,14.44-41.23,10.69-41.23,10.69s-49-9.25-40-58.25c-29-5-96-34-67-108-22-17-51-48-2-85,2-18-26-4-26-4S-2.41,161.7,10,135.77c17.64-36.71,45.64-8.71,45.64-8.71s12.05-15.69,41.44-5.24C107.24,105.31,139.76,70.23,180.22,76.14Z"
    />
    <path
      class="cls-2"
      [ngClass]="{ selected: part == 'zadel' }"
      d="M180.68,76.06l11,1s38-67.81,119.22-59.91c4.56,22.83,11.85,76.92,13.78,194.91-9,0-42.34,1.47-102.17,12.74C220.85,204.33,213.68,139.06,180.68,76.06Z"
    />
    <path
      class="cls-2"
      [ngClass]="{ selected: part == 'vang' }"
      d="M222.68,225.06s14,68-11,189c19-20,33.25-2.34,33.25-2.34s30.75,43.34,66.75,36.34c9-20,19-120,13-236C308.68,212.06,264.68,216.06,222.68,225.06Z"
    />
    <path
      class="cls-2"
      [ngClass]="{ selected: part == 'koteletten' }"
      d="M310.68,17.06l35,11s59.11-24.91,89.56-2.45c-4.11,23.91-18.56,128.45-18.56,193.45-12-4-42-12-92-7C323.68,180.06,322.68,87.06,310.68,17.06Z"
    />
    <path
      class="cls-2"
      [ngClass]="{ selected: part == 'borst' }"
      d="M324.68,212.06s54-7,92,7c-1,14-4.19,108.39,8.41,200.7-12.81.61-38.41-10.7-38.41-10.7s-30,45-75,39C313.68,439.06,331.68,403.06,324.68,212.06Z"
    />
    <path
      class="cls-2"
      [ngClass]="{ selected: part == 'hals' }"
      d="M435.68,25.06l38.46,28.3s-10.46,73.7,34.54,136.7c-9,5-62.57,17.05-90.78-13.48C421.47,140.54,426.68,78.06,435.68,25.06Z"
    />
    <path
      class="cls-2"
      [ngClass]="{ selected: part == 'schouder' }"
      d="M417.68,177.06s32,34,91,13c17,18,36.14,41.37,80.07,59.69-11.14,12.63-15.22,21.57-15.22,21.57S596.15,399,458.92,374.51c.76,4.55,8.76,39.55-34.24,45.55C419.68,373.06,410.68,301.06,417.68,177.06Z"
    />
    <path
      class="cls-2"
      [ngClass]="{ selected: part == 'kop' }"
      d="M474.68,57.06s-21,138,114,193c15-34,19-40,19-40s98,20,114-7c20-34-8-24-26-55-17.07-29.4-45-54-45-54l9-23s-16-41-51-15c-17-8-37-20-55-1C512.68,30.06,474.68,57.06,474.68,57.06Z"
    />
    <path
      class="cls-1"
      d="M147,413.32l-.83,78.24,9,30s18,16,30,0c-2-12-3-19-3-19s38,56,29-11c7-27,22-96,22-96S200.84,447.08,147,413.32Z"
    />
    <path
      d="M112.8,218.66H100V161.41q8.19-.51,11.81-.51,6.64,0,10.3,3.65T125.74,175a12.3,12.3,0,0,1-2.33,7.27,10,10,0,0,1-5.15,4.1,13.61,13.61,0,0,1,7.77,5.43q2.43,3.8,2.44,10.32,0,7.55-4.34,12A15.06,15.06,0,0,1,112.8,218.66Zm-6.68-51v16.45a39.67,39.67,0,0,0,4.78.24q8.7,0,8.71-9,0-7.94-8-7.93A49.81,49.81,0,0,0,106.12,167.66Zm0,22.51V212a42.56,42.56,0,0,0,4.84.39q5.76,0,8.42-2.76c1.76-1.83,2.65-4.76,2.65-8.77,0-3.73-.84-6.47-2.53-8.25S115,190,111.05,190Z"
    />
    <path
      d="M149.59,183.85q0-10,3.93-16.73t10.62-6.73a12.92,12.92,0,0,1,11.53,6.2q4,6.19,4,17.26,0,11.35-4,17.78a12.81,12.81,0,0,1-11.52,6.43q-6.82,0-10.69-6.79T149.59,183.85Zm5.18,0a31.65,31.65,0,0,0,2.45,13.24q2.46,5.31,6.92,5.31,5.06,0,7.71-4.87t2.65-13.68q0-17.81-10.36-17.8c-3.06,0-5.38,1.59-7,4.78S154.77,178.36,154.77,183.85Z"
    />
    <path
      d="M100,244.41h6.14v39.2a13.72,13.72,0,0,0,2.6,8.61,8.28,8.28,0,0,0,6.9,3.39,8.6,8.6,0,0,0,7.14-3.29q2.61-3.29,2.61-8.91v-39h6.13v39.82q0,8.68-4.24,13.54c-2.84,3.25-6.69,4.87-11.58,4.87s-8.9-1.59-11.62-4.77S100,290.13,100,284.19Z"
    />
    <path d="M167.43,251.44v50.22H161.3V251.44H147v-7h35.35v7Z" />
    <line class="cls-3" x1="150.68" y1="214.56" x2="178.68" y2="214.56" />
    <text
      class="cls-4"
      transform="translate(296.27 383.46) rotate(-90) scale(0.91 1.16)"
    >
      V
      <tspan class="cls-5" x="28.68" y="0">ANG</tspan>
    </text>
    <text
      class="cls-6"
      transform="translate(262.15 174.51) rotate(-90) scale(0.91 1.16)"
    >
      LAMS
    </text>
    <text class="cls-7" transform="translate(270.3 77.86) scale(0.91 1.16)">
      Z
      <tspan class="cls-8">
        <tspan x="0" y="25">A</tspan>
      </tspan>
      <tspan x="0" y="50">D</tspan>
      <tspan x="0" y="75">E</tspan>
      <tspan class="cls-9">
        <tspan x="0" y="100">L</tspan>
      </tspan>
    </text>
    <path d="M354.49,66V41.89h2.58V63h8.53v3Z" />
    <path
      d="M382.93,66l-1.3-5.07h-7L373.21,66h-2.89L378,41.56h.68L385.79,66Zm-4.72-18.08-2.92,10.59h5.6Z"
    />
    <path
      d="M366.73,98l-2.24-15.46-4.13,15.79h-.64l-4.24-15.79L353.29,98h-2.45l3.53-24.06h1.18L360,91.45l4.15-17.56h1.16L369.18,98Z"
    />
    <path
      d="M374.74,96.85l.94-3a6.06,6.06,0,0,0,1.8,1.12,5.09,5.09,0,0,0,1.93.45,3.06,3.06,0,0,0,2.42-1.05,3.92,3.92,0,0,0,.92-2.67,5.06,5.06,0,0,0-.51-2.26,6.47,6.47,0,0,0-2.56-2.28l-1.52-.9a6.65,6.65,0,0,1-2.7-2.73,8.59,8.59,0,0,1-.77-3.79,6.67,6.67,0,0,1,1.5-4.47A4.78,4.78,0,0,1,380,73.48c2.08,0,3.54.43,4.35,1.29l-.76,2.86a5.49,5.49,0,0,0-1.57-.92,5.18,5.18,0,0,0-2-.44,2.51,2.51,0,0,0-2.07,1,3.85,3.85,0,0,0-.75,2.45,4.92,4.92,0,0,0,.27,1.67,3.54,3.54,0,0,0,.75,1.27,11.5,11.5,0,0,0,2,1.43l1.55.93a6.74,6.74,0,0,1,2.71,2.79,9.47,9.47,0,0,1,.78,4.11,6.6,6.6,0,0,1-1.71,4.6A5.86,5.86,0,0,1,379,98.36,6.41,6.41,0,0,1,374.74,96.85Z"
    />
    <path
      d="M362.15,130l-5.32-11.07-2.52,4.55V130h-2.57V105.89h2.57V119l7-13.09h2.88l-5.65,10.43L365,130Z"
    />
    <path d="M360.38,140.84V162H357.8V140.84h-6v-3h14.85v3Z" />
    <path
      d="M375.75,140.84v6.69H382v2.79h-6.24V159h8.56v3H373.17V137.89h11.28v3Z"
    />
    <path d="M344.88,194V169.89h2.57V191H356v3Z" />
    <path
      d="M365.17,172.84v6.69h6.24v2.79h-6.24V191h8.56v3H362.59V169.89h11.28v3Z"
    />
    <path d="M388,172.84V194h-2.57V172.84h-6v-3h14.86v3Z" />
    <path
      d="M372.89,116.46a14.52,14.52,0,0,1,1.74-7.42,5.29,5.29,0,0,1,4.71-3,5.74,5.74,0,0,1,5.11,2.75,14.06,14.06,0,0,1,1.77,7.65,14.77,14.77,0,0,1-1.78,7.87,5.68,5.68,0,0,1-5.1,2.85,5.24,5.24,0,0,1-4.74-3A15.47,15.47,0,0,1,372.89,116.46Zm2.29,0a14,14,0,0,0,1.09,5.86c.73,1.57,1.75,2.35,3.07,2.35a3.71,3.71,0,0,0,3.41-2.15,12.87,12.87,0,0,0,1.18-6.06q0-7.89-4.59-7.89a3.35,3.35,0,0,0-3.1,2.12A13.12,13.12,0,0,0,375.18,116.46Z"
    />
    <line class="cls-10" x1="373.37" y1="130.06" x2="385.78" y2="130.06" />
    <path
      d="M371,271.75h-5.76V246c2.45-.15,4.22-.22,5.3-.22a6.22,6.22,0,0,1,4.63,1.64,6.32,6.32,0,0,1,1.64,4.69,5.56,5.56,0,0,1-1,3.27,4.44,4.44,0,0,1-2.32,1.84,6.13,6.13,0,0,1,3.49,2.44,8.54,8.54,0,0,1,1.1,4.64,7.47,7.47,0,0,1-1.95,5.41A6.79,6.79,0,0,1,371,271.75Zm-3-22.92v7.4a18.35,18.35,0,0,0,2.15.1c2.6,0,3.91-1.35,3.91-4.06q0-3.56-3.6-3.56A22.49,22.49,0,0,0,368,248.83ZM368,259v9.81a18.56,18.56,0,0,0,2.17.18,5,5,0,0,0,3.78-1.24,5.5,5.5,0,0,0,1.2-3.94,5.22,5.22,0,0,0-1.14-3.71,5.1,5.1,0,0,0-3.8-1.19Z"
    />
    <path
      d="M375.58,333.75,370.2,322.7c-.62,0-1.59-.06-2.92-.17v11.22h-2.76V308l2.14-.13c1.33-.09,2.3-.13,2.9-.13q7.28,0,7.28,7.38a8.28,8.28,0,0,1-1.17,4.36,5,5,0,0,1-2.79,2.41l5.86,11.84Zm-8.3-22.57v8.36a10.75,10.75,0,0,0,1.93.18,5.27,5.27,0,0,0,3.6-1c.76-.68,1.13-1.89,1.13-3.63a3.7,3.7,0,0,0-1.21-3.11,6.23,6.23,0,0,0-3.83-.95A15.36,15.36,0,0,0,367.28,311.18Z"
    />
    <path
      d="M366,363.57l1-3.23a6.47,6.47,0,0,0,1.93,1.2A5.24,5.24,0,0,0,371,362a3.28,3.28,0,0,0,2.59-1.13,4.2,4.2,0,0,0,1-2.86,5.42,5.42,0,0,0-.54-2.41,6.91,6.91,0,0,0-2.73-2.44l-1.63-1a7.09,7.09,0,0,1-2.89-2.92,9.25,9.25,0,0,1-.82-4.05,7.21,7.21,0,0,1,1.6-4.78,5.15,5.15,0,0,1,4.11-1.89q3.34,0,4.65,1.38L375.5,343a6,6,0,0,0-1.68-1,5.53,5.53,0,0,0-2.09-.47,2.65,2.65,0,0,0-2.21,1,4.08,4.08,0,0,0-.81,2.62A5.38,5.38,0,0,0,369,347a3.84,3.84,0,0,0,.8,1.35,12.22,12.22,0,0,0,2.12,1.53l1.65,1a7.15,7.15,0,0,1,2.9,3,10.1,10.1,0,0,1,.83,4.4,7.07,7.07,0,0,1-1.82,4.92,6.28,6.28,0,0,1-4.88,2A6.85,6.85,0,0,1,366,363.57Z"
    />
    <path d="M372.86,373.18v22.57h-2.75V373.18h-6.42V370h15.88v3.16Z" />
    <path
      d="M364.83,287.17a14.84,14.84,0,0,1,1.77-7.56,5.39,5.39,0,0,1,4.81-3,5.84,5.84,0,0,1,5.21,2.8q1.81,2.81,1.81,7.8,0,5.15-1.81,8.05a5.82,5.82,0,0,1-5.21,2.9,5.35,5.35,0,0,1-4.84-3.07A15.85,15.85,0,0,1,364.83,287.17Zm2.34,0a14.27,14.27,0,0,0,1.11,6c.74,1.6,1.78,2.4,3.13,2.4a3.77,3.77,0,0,0,3.48-2.2,13.1,13.1,0,0,0,1.2-6.19q0-8-4.68-8a3.4,3.4,0,0,0-3.16,2.16A13.3,13.3,0,0,0,367.17,287.17Z"
    />
    <line class="cls-11" x1="365.3" y1="301.06" x2="377.96" y2="301.06" />
    <path
      d="M439.26,96.47l12.77,0q0-4.41,0-8.81H439.59c0-.91,0-1.83,0-2.75h25.72c0,.92,0,1.84,0,2.75H455q0,4.39.05,8.81l10.6,0,.18,2.75c-9.07,0-17.7,0-26.77,0C439.15,98.3,439.21,97.39,439.26,96.47Z"
    />
    <path
      d="M437,119.36c2.35-.41,4.6-.84,6.78-1.27q.25-3.77.46-7.51l-6-1.55c.11-1,.21-2,.31-3.07,9.53,2.8,18.41,5.61,29.18,8.15l.09.72c-10.35,2.7-19.57,5.35-31.19,7.56C436.74,121.38,436.88,120.37,437,119.36Zm22.8-4.92c-4.39-1-8.54-2-12.71-3.11q-.11,3-.23,6C451.3,116.41,455.53,115.44,459.81,114.44Z"
    />
    <path
      d="M435.68,129.13c11.89.44,21.69.44,33.58,0l.39,2.73c-10.67.45-19.7.5-30,.17q-.48,4.56-1,9.14c-1.54-.07-3.1-.16-4.69-.25C434.56,137,435.13,133.06,435.68,129.13Z"
    />
    <path
      d="M435,147.17q2.44.68,4.84,1.3a7.93,7.93,0,0,0-2,1.84,4.11,4.11,0,0,0-1,2,2.81,2.81,0,0,0,1.5,2.73,8.73,8.73,0,0,0,4.44,1.26,12,12,0,0,0,3.84-.42,9,9,0,0,0,3.86-2.74c.51-.55,1-1.1,1.49-1.65a10.22,10.22,0,0,1,4.33-3,20.45,20.45,0,0,1,6-1,16.55,16.55,0,0,1,7.43,1.22c2.1.93,3.29,2.2,3.5,3.86.28,2.21-.29,3.83-1.67,4.85-1.66-.12-3.32-.26-5-.42a5.92,5.92,0,0,0,1.4-1.81,3.81,3.81,0,0,0,.52-2.17,2.64,2.64,0,0,0-1.82-2.12,9.29,9.29,0,0,0-4.1-.61,12.16,12.16,0,0,0-2.7.4,6.55,6.55,0,0,0-2,.88,14,14,0,0,0-2.27,2.21c-.51.58-1,1.15-1.53,1.72a10,10,0,0,1-4.72,3,21.59,21.59,0,0,1-7.11.61,14.44,14.44,0,0,1-7.7-2.41c-2-1.41-2.9-3.14-2.64-5.16A6,6,0,0,1,435,147.17Z"
    />
    <path
      d="M445.27,255.59,446.7,251a9.22,9.22,0,0,0,2.73,1.71,7.78,7.78,0,0,0,2.92.68,4.65,4.65,0,0,0,3.68-1.59,6,6,0,0,0,1.38-4.07,7.59,7.59,0,0,0-.77-3.42c-.51-1.06-1.81-2.21-3.88-3.45l-2.31-1.38a10,10,0,0,1-4.09-4.13,13,13,0,0,1-1.16-5.76,10.19,10.19,0,0,1,2.26-6.78,7.29,7.29,0,0,1,5.83-2.69q4.75,0,6.61,2l-1.16,4.34a8,8,0,0,0-2.38-1.4,7.7,7.7,0,0,0-3-.67,3.79,3.79,0,0,0-3.14,1.44,5.83,5.83,0,0,0-1.14,3.72,7.36,7.36,0,0,0,.41,2.54,5.39,5.39,0,0,0,1.14,1.92,17,17,0,0,0,3,2.17l2.35,1.42a10.22,10.22,0,0,1,4.11,4.22A14.38,14.38,0,0,1,461.3,248a10,10,0,0,1-2.59,7,8.87,8.87,0,0,1-6.93,2.87A9.72,9.72,0,0,1,445.27,255.59Z"
    />
    <path
      d="M490.71,222.1l-1.31,4.26q-1.87-1.74-5.91-1.74-3.76,0-6.05,4.13a22,22,0,0,0-2.27,10.67q0,6.22,2.33,10.11t6,3.87a6.84,6.84,0,0,0,6.2-3.64l2.15,3.82a10.68,10.68,0,0,1-8.76,4.31q-5.61,0-8.83-5.16t-3.23-13.61q0-8.18,3.44-13.58t8.9-5.41A12,12,0,0,1,490.71,222.1Z"
    />
    <path
      d="M519.41,257.26V239.62H506.89v17.64H503V220.75h3.91v14.63h12.52V220.75h3.91v36.51Z"
    />
    <path
      d="M476.1,269.75H480v25a8.74,8.74,0,0,0,1.67,5.5,5.28,5.28,0,0,0,4.39,2.15,5.52,5.52,0,0,0,4.56-2.09,8.89,8.89,0,0,0,1.66-5.68V269.75h3.91v25.4q0,5.53-2.71,8.63a10.43,10.43,0,0,1-14.79.07q-2.59-3-2.6-8.73Z"
    />
    <path
      d="M514.44,306.26h-5.77V269.75c3.68-.16,6-.25,7.06-.25a11.26,11.26,0,0,1,9.5,4.65q3.51,4.65,3.5,12.38Q528.73,306.26,514.44,306.26Zm-1.86-32v27.29a17.52,17.52,0,0,0,3.25.25,7.2,7.2,0,0,0,6.45-3.93q2.36-3.92,2.35-10.9,0-13-9.13-13C515.13,274,514.16,274.07,512.58,274.24Z"
    />
    <path
      d="M466.1,323.24v10.14h9.47v4.24H466.1v13.16h13v4.48h-16.9V318.75H479.3v4.49Z"
    />
    <path
      d="M506.14,355.26l-7.64-15.67c-.88,0-2.26-.08-4.15-.25v15.92h-3.91V318.75l3-.18c1.89-.13,3.26-.19,4.12-.19q10.32,0,10.32,10.47a11.74,11.74,0,0,1-1.66,6.19,7.09,7.09,0,0,1-3.95,3.43l8.31,16.79Zm-11.79-32V335.1a15.34,15.34,0,0,0,2.74.25c2.35,0,4.05-.48,5.12-1.44s1.61-2.69,1.61-5.16q0-3.08-1.73-4.41T496.66,323A19.36,19.36,0,0,0,494.35,323.24Z"
    />
    <path
      d="M444.09,285.38a22,22,0,0,1,2.65-11.27q2.65-4.53,7.15-4.53a8.71,8.71,0,0,1,7.77,4.17q2.7,4.17,2.7,11.63c0,5.09-.9,9.09-2.71,12a8.62,8.62,0,0,1-7.76,4.33q-4.61,0-7.2-4.57T444.09,285.38Zm3.49,0a21.22,21.22,0,0,0,1.65,8.91c1.1,2.39,2.66,3.58,4.66,3.58a5.63,5.63,0,0,0,5.19-3.28q1.79-3.29,1.79-9.21,0-12-7-12c-2.06,0-3.62,1.07-4.7,3.22A19.9,19.9,0,0,0,447.58,285.38Z"
    />
    <line class="cls-12" x1="444.83" y1="306.06" x2="463.69" y2="306.06" />
    <path
      d="M542.47,162.6l-12.52-26L524,147.26V162.6H518V106H524v30.8l16.5-30.8h6.76L534,130.52l15.16,32.08Z"
    />
    <path
      d="M622.1,140.8v21.8H616V106q6.87-.37,8.3-.38,19.35,0,19.34,16.5,0,19.1-17.1,19.09C625.9,141.19,624.4,141.06,622.1,140.8Zm0-27.86v20.91c2.56.25,3.92.38,4.06.38q11.28,0,11.28-11.32,0-10.35-12-10.36A19,19,0,0,0,622.1,112.94Z"
    />
    <path
      d="M565.28,129.6q0-9.9,3.9-16.58t10.52-6.67a12.83,12.83,0,0,1,11.44,6.14q4,6.14,4,17.11,0,11.27-4,17.63a12.71,12.71,0,0,1-11.42,6.38q-6.76,0-10.6-6.74T565.28,129.6Zm5.13,0a31.32,31.32,0,0,0,2.43,13.13q2.45,5.27,6.86,5.26,5,0,7.64-4.83T590,129.6Q590,112,579.7,112q-4.55,0-6.92,4.74T570.41,129.6Z"
    />
    <line class="cls-13" x1="566.36" y1="160.05" x2="594.12" y2="160.05" />
    <text
      class="cls-14"
      transform="translate(177.87 489.78) rotate(-90) scale(0.91 1.16)"
    >
      SCHE
      <tspan class="cls-15">
        <tspan x="0.53" y="19.83">NKE</tspan>
        <tspan class="cls-16" x="47.66" y="19.83">L</tspan>
      </tspan>
    </text>
    <text
      class="cls-14"
      transform="translate(447.12 488.56) rotate(-90) scale(0.91 1.16)"
    >
      SCHE
      <tspan class="cls-15">
        <tspan x="0.53" y="19.83">NKE</tspan>
        <tspan class="cls-16" x="47.66" y="19.83">L</tspan>
      </tspan>
    </text>
  </svg>
</div>

<goenga-default-page
  image="/assets/images/pages/page-recepten.jpg"
  title="Hoe bereid je lamsvlees"
>
  <h3>Texels lamsvlees heeft een unieke eigen smaak</h3>
  <p>
    Lamsvlees van Texelse lammeren heeft een herkenbare eigen smaak. Het
    buitenleven van de lammetjes die dagelijks grazen in de zilte landerijen van
    Texel, proef je terug. Maar hoe bereid je nou lamsvlees? Dat kan op
    verschillende manieren. Je kunt kiezen voor bakken, braden, grillen of
    stoven. We helpen je graag op weg met bereidingstips en recepten.
  </p>

  <h3>Wat ligt er op je bord?</h3>
  <p>
    Minne (1914-1978) was een man met een visie die al snel zag dat het
    groeiende toerisme kansen bood. Hij breidde de slagerij uit met een
    zelfbedieningszaak: oftewel een supermarkt waar je behalve vers vlees, ook
    verse groenten en fruit, brood, melk en andere levensmiddelen kon kopen.
    Zijn zoons Otto en Willem en hun vrouwen Neeltje en Benna kwamen in de zaak
    en het bedrijf groeide door. Niet alleen in oppervlakte, maar ook met
    supermarktfilialen in Oudeschild en op de nabijgelegen camping
    Loodsmansduin. In de jaren tachtig krijgt bovendien het Texelse lamsvlees
    van Goënga landelijke bekendheid. Minnes zoon Willem is de grote promotor De
    lamsvleesproducten scoren hoge punten op internationale slagersvakbeurzen,
    zoals de Slavakto in Utrecht. De lamsham krijgt zelfs vier tienen. Ook de
    lamspaté en droge worst scoren goed.
  </p>
</goenga-default-page>

import { Component, OnInit } from "@angular/core";
import { Meta, Title } from "@angular/platform-browser";
import { ApiGoogle, GetBusinessHoursRespone } from "../api-service";

@Component({
  selector: "goenga-contact",
  templateUrl: "./contact.component.html",
  styleUrls: ["./contact.component.scss"],
})
export class ContactComponent implements OnInit {
  center: google.maps.LatLngLiteral;
  openingHours: GetBusinessHoursRespone;

  constructor(
    private apiGoogle: ApiGoogle,
    title: Title,
    meta: Meta,) {
    title.setTitle("Contact met onze slagerij | Slagerij Goënga");
    meta.addTags([
      { name: 'description', content: 'Contact pagina van slagerij Goënga' },
      { name: 'keywords', content: 'contact,email,lamsvlees,slagerij,texel,den hoorn,echt texels,' },
    ]);
  }

  ngOnInit() {
    this.center = {
      lat: 53.025823,
      lng: 4.752595,
    };

    this.apiGoogle.getBusinessHours().subscribe((businessHours) => {
      this.openingHours = businessHours;
    });
  }

  public mapDaysToString(day: number) {
    switch (day) {
      case 0: {
        return "Zondag";
      }
      case 1: {
        return "Maandag";
      }
      case 2: {
        return "Dinsdag";
      }
      case 3: {
        return "Woensdag";
      }
      case 4: {
        return "Donderdag";
      }
      case 5: {
        return "Vrijdag";
      }
      case 6: {
        return "Zaterdag";
      }
    }
  }
}

import { Component, HostListener, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { CurrentUser } from "src/app/authentication/customer";
import { AuthenticationService } from "src/app/services/authentication.service";
import { SessionStorageService } from "src/app/services/session-storage.service";
import { ShoppingCartService } from "src/app/services/shopping-cart.service";

@Component({
  selector: "app-navbar",
  templateUrl: "./navbar.component.html",
  styleUrls: ["./navbar.component.scss"],
})
export class NavbarComponent implements OnInit {
  public isShown: boolean;
  public showBasket: boolean;
  public user: CurrentUser;
  public isParticulier: boolean = true;

  constructor(
    public authenticationService: AuthenticationService,
    public shoppingCartService: ShoppingCartService,
    public sessionStorageService: SessionStorageService,
    public router: Router
  ) { }

  

  ngOnInit() {
    this.shoppingCartService.change.subscribe((isOpen) => {
      this.showBasket = isOpen;
    });

    this.authenticationService.currentUser.subscribe((x) => {
      this.user = x;
      if (this.user !== null && this.user.customer !== null) {
        this.isParticulier = this.user.customer.isParticulier;
      }
      else {
        this.isParticulier = true;
      }
    });
  }

  navigateForLogin() {
    if (this.user === null) {
      this.router.navigate(["/authenticatie/inloggen"]);
    } else {
      this.router.navigate(["/account"]);
    }
  }

  logout() {
    this.authenticationService.logout();
  }
}

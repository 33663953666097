import { Component, OnInit } from "@angular/core";
import { BsModalRef } from "ngx-bootstrap/modal";
import { Subject } from "rxjs";

@Component({
  selector: "goenga-document-preview-modal",
  templateUrl: "./document-preview-modal.component.html",
  styleUrls: ["./document-preview-modal.component.scss"],
})
export class DocumentPreviewModalComponent implements OnInit {
  public onClose: Subject<boolean>;
  public data: any;

  constructor(private _bsModalRef: BsModalRef) {}

  public ngOnInit(): void {
    this.onClose = new Subject();
  }

  public onConfirm(): void {
    this.onClose.next(true);
    this._bsModalRef.hide();
  }

  public onCancel(): void {
    this.onClose.next(false);
    this._bsModalRef.hide();
  }
}

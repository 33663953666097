import { Injectable } from "@angular/core";
import { OrderRegel } from "../api-service";

@Injectable()
export class OrderCostService {
    constructor() { }

    getItemPrice(item: OrderRegel): number {
        if (item.eenheid.id === 2) {
            // Gr
            return (item.product.consumentPrijsIncBtw / 1000) * item.aantal;
        } else if (item.eenheid.id === 1) {
            // St
            return (
                ((item.product.consumentPrijsIncBtw * item.product.stukGewicht) / 1000) *
                item.aantal
            );
        }

        return -1;
    }

    getShippingCosts(orderRows: OrderRegel[]): number {
        let kilos = 0;

        for (const shoppingCartItem of orderRows) {
            if (shoppingCartItem.eenheid.id === 2) {
                // Gr
                kilos += shoppingCartItem.aantal / 1000;
            } else if (shoppingCartItem.eenheid.id === 1) {
                // St
                kilos +=
                    (shoppingCartItem.product.stukGewicht * shoppingCartItem.aantal) /
                    1000;
            }
        }

        if (kilos < 10) {
            return 12.70;
        } else if (kilos >= 10 && kilos < 30) {
            return 18.95;
        } else {
            return 25.0;
        }
    }

    getSubTotalAmount(orderRows: OrderRegel[]) {
        let total = 0;

        for (const shoppingCartItem of orderRows) {
            total += this.getItemPrice(shoppingCartItem);
        }

        return total;
    }

    getTtotalAmount(orderRows: OrderRegel[]) {
        let total = 0;

        for (const shoppingCartItem of orderRows) {
            total += this.getItemPrice(shoppingCartItem);
        }

        return total + this.getShippingCosts(orderRows);
    }
}
import { Component, Input, OnInit } from "@angular/core";

@Component({
  selector: "goenga-maps-page",
  templateUrl: "./maps-page.component.html",
  styleUrls: ["./maps-page.component.scss"],
})
export class MapsPageComponent implements OnInit {
  @Input() public title: string;
  @Input() public center: google.maps.LatLngLiteral;
  @Input() public size: string = "middle";

  zoom: 15;
  options: google.maps.MapOptions = {
    zoomControl: true,
    scrollwheel: true,
    disableDoubleClickZoom: true,
    maxZoom: 15,
    minZoom: 8,
  };

  markerLabel: {
    color: "#124D26";
    text: "Slagerij Goënga";
  };
  markerTitle: "Slagerij Goënga";
  markerOptions: { animation: google.maps.Animation.BOUNCE };

  constructor() {}

  ngOnInit(): void {}
}

<goenga-maps-page [center]="center" title="Contact met onze slagerij">
  <div class="contact-container">
    <h3>Contact gegevens</h3>
    <p>
      Onze slagerij is gevestigd in onze Spar supermarkt in Den Hoorn op Texel.
      Onze supermarkt en slagerij liggen gelegen aan het dorpsplein in de kern
      van Den Hoorn.
    </p>
    <div class="address">
      <div class="address-row">
        <div class="icon">
          <i class="fa fa-map-marker-alt"></i>
        </div>
        <div class="description">
          <span itemprop="streetAddress">De Naal 1 </span>
        </div>
      </div>

      <div class="address-row">
        <div class="no-icon"></div>
        <div class="description">
          <span itemprop="postalCode">1797 AX </span>
          <span itemprop="addressLocality">Den Hoorn, Texel </span>
        </div>
      </div>

      <div class="address-row">
        <div class="icon">
          <i class="fa fa-phone fa-rotate-90"></i>
        </div>
        <div class="description">
          <span itemprop="telephone">0222 319252 </span>
        </div>
      </div>

      <div class="address-row">
        <div class="icon">
          <i class="fa fa-at"></i>
        </div>
        <div class="description">
          <span itemprop="email">winkel@goenga.com </span>
        </div>
      </div>
    </div>

    <div class="business-hours">
      <h3>Openingstijden</h3>
      <p>
        Onze openingstijden zijn seizoensgebonden. Hieronder staan onze actuele
        openingstijden.
      </p>
      <div class="hour-container" *ngIf="openingHours">
        <div
             class="hour-row"
             *ngFor="let period of openingHours.result.opening_hours.periods">
          <div class="day">{{ mapDaysToString(period.close.day) }}</div>
          <div class="time">
            {{ period.open.time | time }} - {{ period.close.time | time }}
          </div>
        </div>
      </div>
      <!-- <div class="special-hours">
        <h3>Uitzonderingen</h3>
        <div class="special-hour-row">
          <div class="description">
            2e pinksterdag
            <span class="date">(24-05-2021)</span>
          </div>
          <div class="time">09:00 - 13:00</div>
        </div>
      </div> -->
    </div>
  </div>
</goenga-maps-page>
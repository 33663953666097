import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { HeaderComponent } from "./header/header.component";
import { NavbarComponent } from "./navbar/navbar.component";
import { FooterComponent } from "./footer/footer.component";
import { RouterModule } from "@angular/router";
import { SharedModule } from "../shared/shared.module";
import { LazyLoadImageModule } from "ng-lazyload-image";

@NgModule({
  declarations: [HeaderComponent, NavbarComponent, FooterComponent],
  imports: [CommonModule, SharedModule, RouterModule, LazyLoadImageModule],
  exports: [HeaderComponent, NavbarComponent, FooterComponent],
})
export class LayoutModule {}

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'goenga-azure-image',
  templateUrl: './azure-image.component.html',
  styleUrls: ['./azure-image.component.scss']
})
export class AzureImageComponent {
  public imageUrl: string;
  public azureBasicUrl: string = "https://enjuportalstorage.blob.core.windows.net";

  @Input() imageName: string;
  @Input() height: string;
  @Input() width: string;

  constructor() { }

  ngOnChanges() {
    var tenantId = environment.tenantId;
    this.imageUrl = `${this.azureBasicUrl}/${tenantId}/thumbnails/${this.imageName}`;
  }
}

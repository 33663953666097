<div class="modal-body">
  <button
    type="button"
    class="close pull-right"
    (click)="onCancel()"
    aria-label="Close"
  >
    <i class="fa fa-times"></i>
  </button>
  <goenga-loader *ngIf="!data"></goenga-loader>
  <div *ngIf="data" class="zoom-mobile" [innerHTML]="data"></div>
</div>

import { Injectable } from "@angular/core";
import { OrderRegel } from "../api-service";
import { PersonalData } from "../shared/personal-data";

@Injectable()
export class SessionStorageService {
  private _shoppingCartItems: OrderRegel[];
  private _personalData: PersonalData;
  private _processStatus: number;
  private _scrollPosition: number;
  private _catogory: number;

  constructor() {}

  get shoppingCartItems(): OrderRegel[] {
    if (this._shoppingCartItems == null) {
      // Get shopping cart items from session storage and parse it to an object
      const sessionData = JSON.parse(
        sessionStorage.getItem("shoppingCartItems")
      );

      // Parse object
      this._shoppingCartItems =
        sessionData == null ? [] : this.castToOrderRegels(sessionData);
    }

    return this._shoppingCartItems;
  }

  set shoppingCartItems(value: OrderRegel[]) {
    // Save new values
    this._shoppingCartItems = value;

    // Save new values to session storage
    sessionStorage.setItem(
      "shoppingCartItems",
      JSON.stringify(this._shoppingCartItems)
    );
  }

  get personalData(): PersonalData {
    if (this._personalData == null) {
      // Get personal data from session storage and parse it to an object
      const sessionData = JSON.parse(sessionStorage.getItem("personalData"));

      // Parse object

      this._personalData = sessionData;
    }

    return this._personalData;
  }

  set personalData(value: PersonalData) {
    // Save new values
    this._personalData = value;

    // Save new values to session storage
    sessionStorage.setItem("personalData", JSON.stringify(this._personalData));
  }

  get processStatus(): number {
    if (this._processStatus == null) {
      // Get shopping cart items from session storage and parse it to an object
      const sessionData = JSON.parse(sessionStorage.getItem("processStatus"));

      // Parse object
      this._processStatus = sessionData == null ? 1 : sessionData;
    }

    return this._processStatus;
  }

  set processStatus(value: number) {
    // Save new values
    this._processStatus = value;

    // Save new values to session storage
    sessionStorage.setItem(
      "processStatus",
      JSON.stringify(this._processStatus)
    );
  }

  get scrollPosition(): number {
    if (this._scrollPosition == null) {
      // Get shopping cart items from session storage and parse it to an object
      const sessionData = sessionStorage.getItem("scrollStatus");

      // Parse object
      this._scrollPosition = sessionData == null ? 1 : Number(sessionData);
    }

    return this._scrollPosition;
  }

  set scrollPosition(value: number) {
    // Save new values
    this._scrollPosition = value;

    // Save new values to session storage
    sessionStorage.setItem("scrollStatus", this._scrollPosition.toString());
  }

  get category(): number {
    if (this._catogory == null) {
      // Get shopping cart items from session storage and parse it to an object
      const sessionData = sessionStorage.getItem("category");

      // Parse object
      this._catogory = sessionData == null ? 1 : Number(sessionData);
    }

    return this._catogory;
  }

  set category(value: number) {
    // Save new values
    this._catogory = value;

    // Save new values to session storage
    sessionStorage.setItem("category", this._catogory.toString());
  }

  private castToOrderRegels(sessionData: any): OrderRegel[] {
    const orderRegels = new Array<OrderRegel>();

    for (const data of sessionData) {
      orderRegels.push(OrderRegel.fromJS(data));
    }

    return orderRegels;
  }
}

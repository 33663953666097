import { Component, Input, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { TypeaheadMatch } from "ngx-bootstrap/typeahead";
import { ApiProduct, Product } from "../../../api-service";
import { isBs3 } from "ngx-bootstrap/utils";

@Component({
  selector: "goenga-searcher",
  templateUrl: "./searcher.component.html",
  styleUrls: ["./searcher.component.scss"],
})
export class SearcherComponent implements OnInit {
  public isBs3 = isBs3();
  public isLoading: boolean;
  public selected: searchObject;
  public searchItems: searchObject[] = [];

  @Input() dropup: boolean;

  constructor(private apiProduct: ApiProduct, private route: Router) {}

  public ngOnInit() {
    this.isLoading = true;

    // over
    let aboutSearch = new searchObject();
    aboutSearch.name = "Over slagerij Goënga";
    aboutSearch.description = "Wij zijn wij, en wat drijft onze slagerij?";
    aboutSearch.type = searchObjectType.Page;
    aboutSearch.link = "/over-slagerij-goenga";
    this.searchItems.push(aboutSearch);

    // recpenten
    let receptSearch = new searchObject();
    receptSearch.name = "Recepten en bereiding";
    receptSearch.description =
      "Hoe maak ik het vlees klaar? En wat zijn onze lekkerste recepten?";
    receptSearch.type = searchObjectType.Page;
    receptSearch.link = "/heerlijke-recpeten";
    this.searchItems.push(receptSearch);

    // order info
    let orderInfoSearch = new searchObject();
    orderInfoSearch.name = "Hoe werkt het bestellen?";
    orderInfoSearch.description =
      "Hoe kunt u bij ons bestellen en hoe moet ik bij jullie betalen?";
    orderInfoSearch.type = searchObjectType.Page;
    orderInfoSearch.link = "/informatie/hoe-werkt-het-bestellen";
    this.searchItems.push(orderInfoSearch);

    // order info
    let orginInfoSearch = new searchObject();
    orginInfoSearch.name = "Waar komt ons vlees vandaan?";
    orginInfoSearch.description =
      "Wil je weten waar ons vlees vandaan komt op Texel?";
    orginInfoSearch.type = searchObjectType.Page;
    orginInfoSearch.link = "/herkomst-van-ons-vlees";
    this.searchItems.push(orginInfoSearch);

    // order info
    let shipmentInfoSearch = new searchObject();
    shipmentInfoSearch.name = "Hoe wordt mijn bestelling verzonden?";
    shipmentInfoSearch.description = "Snel en gekoeld bij jou thuis?";
    shipmentInfoSearch.type = searchObjectType.Page;
    shipmentInfoSearch.link = "/informatie/verzend-informatie";
    this.searchItems.push(shipmentInfoSearch);

    this.apiProduct.getForSite().subscribe((products) => {
      products.forEach((product) => {
        let item = new searchObject();
        item.name = product.naam;
        item.description = product.omschrijving;
        item.link = `/bestellen/product/${product.id}/details/${product.naam}`;
        item.type = searchObjectType.Product;
        item.imageUrl = product.fotoPad;
        this.searchItems.push(item);
      });
      this.isLoading = false;
    });
  }

  onSelect(event: TypeaheadMatch): void {
    this.route.navigateByUrl(event.item.link);
    this.selected = null;
  }
}

export class searchObject {
  public name: string;
  public description: string;
  public link: string;
  public type: searchObjectType;
  public imageUrl: string;
}

export enum searchObjectType {
  Product = "Producten",
  Page = "Informatie",
}

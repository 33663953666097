import { Component, Input, OnInit } from "@angular/core";

@Component({
  selector: "goenga-lamb-specify-image",
  templateUrl: "./lamb-specify-image.component.html",
  styleUrls: ["./lamb-specify-image.component.scss"],
})
export class LambSpecifyImageComponent implements OnInit {
  @Input() part: string;

  constructor() {}

  ngOnInit(): void {
    console.log(this.part)
  }
}

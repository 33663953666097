import { Component, HostListener, OnInit } from "@angular/core";
import { Meta, Title } from "@angular/platform-browser";
import { Router } from "@angular/router";
import { SessionStorageService } from "../services/session-storage.service";

@Component({
  selector: "goenga-home",
  templateUrl: "./home.component.html",
  styleUrls: ["./home.component.scss"],
})
export class HomeComponent implements OnInit {
  constructor(
    private router: Router,
    private sessionStorageService: SessionStorageService,
    title: Title,
    meta: Meta,) {
    title.setTitle("Slagerij Goënga | Echt Texels lamsvlees komt van Slagerij Goënga");
    meta.addTags([
      { name: 'description', content: 'Topkoks en culifanaten weten het allang: goed lamsvlees komt van Texel. Van Slagerij Goënga om precies te zijn. Van lammeren die zijn geboren en opgegroeid op de landerijen van Waddeneiland Texel. Na een goed leven in vrijheid worden ze professioneel geslacht en met liefde verwerkt tot goed, verantwoord vlees. De slagers van Goënga weten hoe dat moet. Dat doen ze al sinds 1935.' },
      { name: 'keywords', content: 'lamsvlees, lamsrack, lamsbout,lamskotelet, lamasschouder, lamskarobnade, lamsgehakt, lamsmuis, lamshaas, lamsfilet, lamsrug, lamsham, lamsworst,waddengoud, echt texels, Echt Texels Lamsvlees, lamsvlees bestellen, bestellen, on-line, lam, bezorging door heel Nederland, vers, altijd in voorraad, binnen 24 uur bezorgd,slagerij goenga, Den Hoorn, Texel, kaas, brandnetel, mosterd, peper, boerenkaas, kaas, schapenkaas, wild, echt texels' },
    ]);
  }

  ngOnInit() {
    document.querySelectorAll('.menu-bar').forEach((c) => {
      c.classList.add('home');
    });
  }

  ngOnDestroy() {
    document.querySelectorAll('.menu-bar').forEach((c) => {
      c.classList.remove('home');
    });
  }

  @HostListener("window:scroll", [])
  onWindowScroll() {
    const number = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
    if (number > 0) {
      document.querySelectorAll('.menu-bar').forEach((c) => {
        c.classList.add('scrolled');
      });
    } else {
      document.querySelectorAll('.menu-bar').forEach((c) => {
        c.classList.remove('scrolled');
      });
    }
  }

  navigate(url) {
    this.router.navigate([url]);
    //this.gaService.pageView(url, url);
  }

  navigateToOrder(category: number) {
    //this.gaService.event("select_category_home", "category_selection", category.toString());
    this.sessionStorageService.category = category;

    //this.gaService.pageView('/bestellen', "bestellen");
    this.router.navigate(["/bestellen"]);
  }
}

<goenga-default-page
  image="/assets/images/pages/page-over-ons.jpg"
  title="Texelse slagers met een missie."
>
  <h3>Slagerij met goede naam</h3>
  <p>
    Vijf generaties terug zag Otte Goënga, in het begin van de 20e eeuw, al
    mogelijkheden voor een slagerij in het Texelse dorp Den Hoorn. Hij
    investeerde in een kleine slagerij voor zijn zoon Minne. Die werd direct
    gegrepen door het slagersvak. Na een slagersopleiding en goede leerschool in
    Friesland startte hij zijn eigen bedrijf. Met passie en respect voor de
    dieren die hij verwerkte, bouwde Minne een slagerij met goede naam op. Met
    kwalitatief hoogwaardige en smaakvolle producten wisten klanten al snel de
    weg naar de slagerij te vinden.
  </p>

  <h3>Ondernemerschap</h3>
  <p>
    Minne (1914-1978) was een man met een visie die al snel zag dat het
    groeiende toerisme kansen bood. Hij breidde de slagerij uit met een
    zelfbedieningszaak: oftewel een supermarkt waar je behalve vers vlees, ook
    verse groenten en fruit, brood, melk en andere levensmiddelen kon kopen.
    Zijn zoons Otto en Willem en hun vrouwen Neeltje en Benna kwamen in de zaak
    en het bedrijf groeide door. Niet alleen in oppervlakte, maar ook met
    supermarktfilialen in Oudeschild en op de nabijgelegen camping
    Loodsmansduin. In de jaren tachtig krijgt bovendien het Texelse lamsvlees
    van Goënga landelijke bekendheid. Minnes zoon Willem is de grote promotor De
    lamsvleesproducten scoren hoge punten op internationale slagersvakbeurzen,
    zoals de Slavakto in Utrecht. De lamsham krijgt zelfs vier tienen. Ook de
    lamspaté en droge worst scoren goed.
  </p>

  <h3>Vlees als stevige basis</h3>
  <p>
    Sinds 2002 wordt Slagerij en Supermarkt Goënga in Den Hoorn succesvol geleid
    door de vierde generatie: Minne en Winanda Goënga. Zij hebben bovendien het
    Texelse lamsvlees ook buiten het eiland nog meer op de kaart gezet, waarbij
    ze zijn blijven vasthouden aan een beproefd concept: eerlijk en verantwoord
    vlees.
  </p>

  <p>
    Minne: ‘Wij weten van ieder stukje lamsvlees van welke boerderij en zelfs
    van welk lam het afkomstig is. Onze zoon Len heeft een traceersysteem
    ontwikkeld waarmee we dat inzichtelijk kunnen maken. Wij leveren alleen
    vlees van lammeren die op Texel zijn geboren, geweid en geslacht. En van
    boeren die we kennen en waarvan we weten dat ze goed zijn voor hun dieren.’
  </p>

  <h3>Het hele jaar vers lamsvlees</h3>

  <p>
    Door afspraken te maken met de boeren over het lammeren, is het niet meer zo
    dat alleen in het voorjaar vers lamsvlees te verkrijgen is. ‘Dat is elders
    in Nederland nog wel zo, maar niet op Texel. Hier worden het hele jaar door
    lammeren geboren. Ook in de winter. Daardoor kunnen we altijd vers lamsvlees
    aanbieden. Dat wordt vacuüm verpakt en gekoeld verstuurd in speciaal
    ontwikkelde ecoboxen’, vertelt Minne. ‘Die boxen zijn duurzaam door het
    gebruik van karton en issolatiemateriaal van oud papieren. Dat was voor ons
    een vereiste.’
  </p>

  <h3>Gepassioneerde slagers</h3>

  <p>
    De slagers van Goënga zijn mannen met passie voor hun vak. ‘Alle Texelse
    lammeren, maar ook de koeien en varkens van het eiland die wij verwerken,
    hebben een goed en gezond leven gehad. Dat is voor ons een vereiste. Niet in
    overvolle stallen, maar lekker in het weiland, in de zilte buitenlucht.
    Daardoor krijg je kwaliteitsvlees dat past in een verantwoorde keuze van
    consumenten.’ Na de uitbreidingen met meerdere filialen is de firma Goënga
    inmiddels alleen nog actief op de plek waar het ooit begon: in het hart van
    Den Hoorn. Met de focus op lekker en verantwoord vlees en vleeswaren. De
    slagerij heeft een grote schare vaste klanten over heel Texel en daarbuiten.
    In de slagerij, inpandig in de supermarkt, werken drie slagers en vijf
    verkopers. In de supermarkt zelf zijn vijf vaste fulltimers en een vulploeg
    van zo’n vijftien tot vijfentwintig mensen actief. Minne: ‘Ik ben nog de
    enige Goënga in het bedrijf, maar we hebben medewerkers die hier al 25 en
    zelfs 40 jaar werken. Dat voelt als familie.’
  </p>
</goenga-default-page>
